import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
  useRef,
} from 'react';
import Header from '../../layout/Header';
import {
  Box,
  Typography,
  Button,
  Divider,
  MenuItem,
  Skeleton,
  CircularProgress,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { loadModules } from 'esri-loader';
import { motion } from 'framer-motion';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import marker from '../../CustomVestmapViewer/assets/marker center.png';

import {
  Pencil,
  Save,
  GripVertical,
  Globe,
  GlobeLock,
  Undo2,
} from 'lucide-react';
import { ReactComponent as Arrows } from '@/../../public/arrowsdark.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAuth } from '../../../AuthProvider';

import hpiChart from '@/../../public/hpi chart.png';

import { ReactComponent as DemoIconDark } from '@/../../public/demoIconDark.svg';
import { ReactComponent as DemoIconLight } from '@/../../public/demoIconLight.svg';

import { ReactComponent as CrimeIconDark } from '@/../../public/crimeIconDark.svg';
import { ReactComponent as RentIconDark } from '@/../../public/rentIconDark.svg';
import { ReactComponent as DeleteIcon } from '@/../../public/deleteIconRed.svg';
import { ReactComponent as IncomeIconDark } from '@/../../public/incomeIconDark.svg';

import { ReactComponent as IncomeIconLight } from '@/../../public/incomeIconLight.svg';

import { ReactComponent as HPIIconLight } from '@/../../public/hpiIconLight.svg';
import { ReactComponent as HPIIconDark } from '@/../../public/hpiIconDark.svg';

import { ReactComponent as SchoolsIconLight } from '@/../../public/schoolIconLight.svg';
import { ReactComponent as SchoolsIconDark } from '@/../../public/schoolIconDark.svg';

import { ReactComponent as CrimeIconLight } from '@/../../public/crimeIconLight.svg';

import { ReactComponent as ExpansionIconLight } from '@/../../public/expansionIconLight.svg';
import { ReactComponent as ExpansionIconDark } from '@/../../public/expansionIconDark.svg';

import { ReactComponent as RentIconLight } from '@/../../public/rentIconLight.svg';

import { ReactComponent as NeighborhoodIconLight } from '@/../../public/neighborhoodIconLight.svg';
import { ReactComponent as NeighborhoodIconDark } from '@/../../public/neighborhoodIconDark.svg';

import { ReactComponent as MSAIconLight } from '@/../../public/msaIconLight.svg';
import { ReactComponent as MSAIconDark } from '@/../../public/msaIconDark.svg';

import { ReactComponent as AiAnalysisDark } from '@/../../public/flaskDark.svg';
import { ReactComponent as AiAnalysisLight } from '@/../../public/flaskLight.svg';

import './template.module.css';
import { FixedSizeList } from 'react-window';
import Draggable from 'react-draggable';
import { useNavigate } from 'react-router-dom';
import Alert from './components/Alert';

const ReportTemplatesContext = createContext();

const sectionsJson = [
  {
    // Blocks completed
    title: 'Demographics',
    key: 'demographics',

    blocks: [
      {
        title: 'Grade',
        status: false,
        outfields: ['grade'],
      },
      {
        title: 'Demographic Group',
        status: false,
        outfields: ['group'],
      },
      {
        title: 'Nearest Groups',
        status: false,
        outfields: ['nearest_groups'],
      },
      {
        title: 'Who We Are',
        status: false,
        outfields: ['who_we_are'],
      },
    ],

    status: false,
  },
  {
    // Blocks completed
    title: 'Crime',
    key: 'crime',

    blocks: [
      {
        title: 'Overall Crime Index Score',
        status: false,
        outfields: ['overall_crime_index_score'],
      },
      {
        title: 'Property Crime Index Score',
        status: false,
        outfields: ['property_crime_index_score'],
      },
    ],
    status: false,
  },
  {
    title: 'Expansion',
    key: 'expansion',

    status: false,
    blocks: [
      {
        title: 'Block',
        status: false,
        outfields: ['block'],
      },
      {
        title: 'Tract',
        status: false,
        outfields: ['tract'],
      },
      {
        title: 'Zip',
        status: false,
        outfields: ['zip'],
      },
      {
        title: 'County',
        status: false,
        outfields: ['county'],
      },
      {
        title: 'State',
        status: false,
        outfields: ['state'],
      },
      {
        title: 'National',
        status: false,
        outfields: ['national'],
      },
    ],
  },
  {
    title: 'Income & Spending',
    key: 'income',

    status: false,
    blocks: [
      // Top Row with 6 inner blocks
      {
        title: 'Median Household Income',
        status: false,
        outfields: ['median_household_income'],
      },
      // Second Row with 3 inner blocks
      {
        title: 'Median Home Value',
        status: false,
        outfields: ['median_home_value'],
      },
      // Last Row with 2 inner blocks
      {
        title: 'Annual Forecasted Median Income Growth',
        status: false,
        outfields: ['annual_forecasted_median_income_growth'],
      },
    ],
  },
  {
    title: 'Rent',
    key: 'rent',

    status: false,
    blocks: [
      {
        title: 'Average',
        status: false,
        outfields: ['average'],
      },
      {
        title: 'Median',
        status: false,
        outfields: ['median'],
      },
      {
        title: '25 ~ Percentile',
        status: false,
        outfields: ['percentile_25'],
      },
      {
        title: '75 ~ Percentile',
        status: false,
        outfields: ['percentile_75'],
      },
    ],
  },
  {
    title: 'Neighborhood',
    key: 'neighborhood',

    status: false,
    blocks: [
      {
        title: 'Nearest Stores',
        status: false,
        outfields: ['StoreName'],
      },
    ],
  },
  {
    title: 'House Price Index',
    key: 'hpi',

    status: false,
    blocks: [
      {
        // For top row
        title: 'Price Appreciation Since 2011',
        status: false,
        outfields: ['price_appreciation_since_2011'],
      },
      {
        // For bottom row
        title: 'Background Rate of Appreciation (1990 - present)',
        status: false,
        outfields: ['background_rate_of_appreciation'],
      },
      {
        // For the chart
        title: 'HPI TRACT/ZIP INDEX',
        status: false,
        outfields: ['hpi_tract_zip_index'],
      },
    ],
  },
  {
    title: 'MSA',
    key: 'msa',

    status: false,
    blocks: [
      {
        title: 'Stat Block',
        status: false,
        outfields: ['stat_block'],
      },
      {
        title: 'Business Diversification Index',
        status: false,
        outfields: ['business_diversification_index'],
      },
      {
        title: 'Employers Diversification Index',
        status: false,
        outfields: ['employers_diversification_index'],
      },
      {
        title: 'Top 5 Businesses by Sector',
        status: false,
        outfields: ['top_businesses'],
      },
      {
        title: 'Top 5 Employers by Sector',
        status: false,
        outfields: ['top_employers'],
      },
    ],
  },
  {
    title: 'Schools',
    key: 'schools',

    status: false,
    blocks: [
      {
        title: 'School District Name',
        status: false,
        outfields: ['district_name'],
      },
      {
        title: 'Nearest 3 Schools',
        status: false,
        outfields: ['nearest_3_schools'],
      },
    ],
  },
];

// todo - move to a separate file and add more colors
const colors = [
  {
    color: '#8d9a05',
    backgroundColor: '#f4fca2',
  },
  {
    color: '#8c0606',
    backgroundColor: '#fa9797',
  },
  {
    color: '#034010',
    backgroundColor: '#adfbbd',
  },
  {
    color: '#001929',
    backgroundColor: '#90d3fe',
  },
  {
    color: '#84047e',
    backgroundColor: '#ffeffe',
  },
];

const theme = createTheme({
  palette: {
    primary: {
      main: '#9c27b0', // Purple
    },
    secondary: {
      main: '#3f51b5', // Indigo
    },
  },
});

function getColor(index) {
  return colors[index % colors.length];
}

function ReportTemplates() {
  // todo - when making it responsive, this will be used to toggle the sidebar in mobile view
  const navigate = useNavigate();
  const { getAccessToken, user } = useAuth();

  const [adminLoader, setAdminLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [arcgisToken, setArcgisToken] = useState(null);
  const [editing, setEditing] = useState(false);
  const [templateID, setTemplateID] = useState(null);
  const [sideBarState, setSideBarState] = useState(false);
  const [currentStage, setCurrentStage] = useState('templateTable');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [lastStage, setLastStage] = useState('createTemplate');
  const [addBlockModal, setAddBlockModal] = useState({
    status: false,
    callingSection: null,
  });
  const [Sections, setSections] = useState(() =>
    JSON.parse(JSON.stringify(sectionsJson)),
  );

  const [Maps, setMaps] = useState(null);

  // * Helper Functions
  function removeBlock(blockName, sectionKey, groupKey = null) {
    const updatedSections = Sections.map(section => {
      if (section.key === sectionKey) {
        section.blocks = section.blocks.map(block => {
          if (block.title === blockName && block.group === groupKey) {
            block.status = false;
          }
          return block;
        });
      }
      return section;
    });

    setSections(updatedSections);
  }

  function addCustomMapInfo(sectionKey, selectedBlock) {
    const updatedSections = Sections.map(section => {
      if (section.key === sectionKey) {
        console.log({ selectedBlock });
        section.mapBlock = selectedBlock;
        section.title = selectedBlock.title + ` (${selectedBlock.group})`;
        return section;
      }
      return section;
    });

    setSections(updatedSections);
  }

  function updateBlockTitle(oldTitle, newTitle, sectionKey) {
    const updatedSections = Sections.map(section => {
      if (section.key === sectionKey) {
        section.blocks = section.blocks.map(block => {
          if (block.title === oldTitle) {
            return { ...block, title: newTitle };
          }
          return block;
        });
      }
      return section;
    });

    setSections(updatedSections);
  }

  function updatedBlockPrefixAndSuffix(
    blockTitle,
    sectionKey,
    prefix = null,
    suffix = null,
  ) {
    const updatedSections = Sections.map(section => {
      if (section.key === sectionKey) {
        section.blocks = section.blocks.map(block => {
          if (block.title === blockTitle) {
            return { ...block, prefix, suffix };
          }
          return block;
        });
      }
      return section;
    });

    console.log({ fixUpdate: updatedSections }); // take me out
    setSections(updatedSections);
  }

  function updateBlockValue(blockTitle, sectionKey, value, group = null) {
    const updatedSections = Sections.map(section => {
      if (section.key === sectionKey) {
        section.blocks = section.blocks.map(block => {
          if (block.title === blockTitle && block.group === group) {
            return { ...block, templateValue: value };
          }
          return block;
        });
      }
      return section;
    });

    console.log({ fixUpdate: updatedSections }); // take me out
    setSections(updatedSections);
  }

  function clearStates() {
    setSections(JSON.parse(JSON.stringify(sectionsJson)));
    setEditing(false);
    setTemplateID(null);
    setNewTemplateName('');
    setLastStage('createTemplate');
    setCurrentStage('templateTable');
  }

  async function saveTemplate() {
    try {
      let customSections = Sections.map(section => {
        const tempSection = {};
        if (!section.status) return undefined;

        tempSection.key = section.key;
        tempSection.dynamicBlocks = [];

        if (section.map.status || section.status) tempSection.map = '';
        else return undefined;

        tempSection.webmapID = section.map.webmapID;
        tempSection.mapBlock = section.mapBlock;
        tempSection.title = section.title;

        tempSection.dynamicBlocks = section.blocks
          .filter(block => block.status)
          .map(block => ({
            title: block.title,

            outfields: block.outfields || block.outFields,
            serviceURL: block.serviceURL || null,
            prefix: block.prefix,
            suffix: block.suffix,
            group: block?.group || '',
            templateValue: block?.templateValue || '',
            value: '',
            key: section.key,
            layout: 'l1',
          }));

        return tempSection;
      });
      customSections = customSections.filter(Boolean);

      const user = JSON.parse(localStorage.getItem('mongodbUser'));
      if (!user) throw new Error('User not found in localstorage');

      const template = {
        templateName: newTemplateName,
        customSections,

        ...(editing === false && {
          userID: user._id,
          theme: colors[Math.floor(Math.random() * colors.length)],
        }),
      };

      console.log({ template });

      // return;
      let url;
      if (editing)
        url = process.env.REACT_APP_NODE_URL + '/templates/' + templateID;
      else url = process.env.REACT_APP_NODE_URL + '/templates/add';

      const res = await fetch(url, {
        method: editing ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(template),
      });

      if (!res.ok) throw new Error('Endpoint Response Error');
      clearStates();
    } catch (error) {
      console.error(error);
    }
  }

  async function loadTemplate(template) {
    try {
      console.log({ loadedTemplate: template });
      const { templateName, sections } = template;

      setNewTemplateName(templateName);

      const updatedSections = [...Sections]; // Create a copy of the current sections

      sections.forEach(loadedSection => {
        const existingSection = updatedSections.find(
          section => section.key === loadedSection.key,
        );

        if (existingSection) {
          // Update existing section
          existingSection.status = true;
          existingSection.map.status = true;
          existingSection.mapBlock = loadedSection.mapBlock || null;
          existingSection.title = loadedSection?.title || existingSection.title;

          if (loadedSection.dynamicBlocks.length > 0) {
            existingSection.blocks = existingSection.blocks.map(block => {
              const loadedBlock = loadedSection.dynamicBlocks.find(
                dynamicBlock => dynamicBlock.key === block.key,
              );
              block.status = loadedBlock ? true : false;
              return block;
            });

            loadedSection.dynamicBlocks.forEach(dynamicBlock => {
              const existingBlock = existingSection.blocks.find(
                block =>
                  block.title === dynamicBlock.title &&
                  block?.group === dynamicBlock?.group,
              );
              if (!existingBlock) {
                existingSection.blocks.push({ ...dynamicBlock, status: true });
              }
            });
          }
        } else {
          // Add new section
          updatedSections.push({
            ...loadedSection,
            status: true,
            map: {
              ...loadedSection.map,
              status: true,
              webmapID: loadedSection?.webmapID,
              key: loadedSection?.key,
            },
            blocks: loadedSection.dynamicBlocks.map(block => ({
              ...block,
              status: true,
            })),
          });
        }
      });

      console.log({ updatedSections });
      // Update the state with the modified sections
      setSections(updatedSections);
      setEditing(true);
      setTemplateID(template._id);
      setNewTemplateName(template.templateName);
      setSections(updatedSections);
      setCurrentStage('createTemplate');
      setLastStage('saveAndFinish');
    } catch (error) {
      console.error('Error loading template:', error);
    }
  }

  async function getToken() {
    try {
      console.log('Getting Access Token from server');
      const accessToken = await getAccessToken();
      const url = process.env.REACT_APP_NODE_URL + '/admin/token';
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) throw new Error('Response error');

      const data = await response.json();
      if (data?.token) {
        console.log('Token received from server');
        setArcgisToken(data.token.access_token);
        return data.token.access_token;
      }
      throw new Error('Token not found');
    } catch (error) {
      console.error('Error getting token');
      console.error(error);
    }
  }

  async function getBlocksFromArcGIS(sectionKey) {
    try {
      const token = await fetchToken();

      const map = Maps.find(map => map.key === sectionKey);
      if (!map) {
        throw new Error(`Map with key ${sectionKey} not found`);
      }

      const layerUrlsAndTitles = await fetchLayerUrlsAndTitles(
        map.webmapID,
        token,
      );

      const layerUrls = layerUrlsAndTitles.map(item => item.url);
      const newBlocks = await fetchFields(layerUrls, token);

      updateSections(sectionKey, newBlocks);
      setLoading(false);
    } catch (error) {
      console.log('Error occurred in getBlocksFromArcGIS function');
      console.error(error);
      setLoading(false);
    }
  }

  async function fetchToken() {
    if (!arcgisToken) {
      return await getToken();
    }
    return arcgisToken;
  }

  async function fetchLayerUrlsAndTitles(webmapID, token) {
    const layerFetchingUrl = `https://www.arcgis.com/sharing/rest/content/items/${webmapID}/data`;
    const layerFetchingParams = new URLSearchParams({ f: 'pjson', token });
    const response = await fetch(
      `${layerFetchingUrl}?${layerFetchingParams.toString()}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );

    if (!response.ok) {
      throw new Error("Couldn't fetch the layers");
    }

    const data = await response.json();
    if (!data.operationalLayers) {
      throw new Error('Operational layers not found in the webmap');
    }

    const layersInfo = [];
    data.operationalLayers.forEach(operationalLayer => {
      if (operationalLayer?.url) {
        const baseLayerInfo = {
          url: operationalLayer.url,
          title: operationalLayer.title || 'No title available',
        };

        layersInfo.push(baseLayerInfo);

        if (operationalLayer.visibleLayers) {
          const finalPart =
            operationalLayer.url.split('/')[
              operationalLayer.url.split('/').length - 1
            ];
          if (!+finalPart) {
            operationalLayer.visibleLayers.forEach(layer => {
              const visibleLayerInfo = {
                url: `${operationalLayer.url}/${layer}`,
                title: operationalLayer.title || `Layer ${layer}`,
              };
              layersInfo.push(visibleLayerInfo);
            });
          }
        }
      }

      if (operationalLayer?.layers) {
        operationalLayer.layers.forEach(layer => {
          if (layer?.url) {
            const layerInfo = {
              url: layer.url,
              title: layer.title || 'No title available',
            };
            layersInfo.push(layerInfo);
          }
        });
      }
    });

    return layersInfo;
  }

  async function fetchFields(layerUrls, token) {
    const fieldPromises = layerUrls.map(async url => {
      try {
        const response = await fetch(`${url}?f=pjson&token=${token}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch data from ${url}`);
        }
        const data = await response.json();
        return {
          name: data.name || '',
          fields: data.fields || [],
          serviceURL: url,
        };
      } catch (error) {
        console.error(`Error fetching fields from ${url}:`, error);
        return {
          name: '',
          fields: [],
          serviceURL: null,
        };
      }
    });

    const fieldsResponse = await Promise.all(fieldPromises);

    fieldsResponse.forEach(fieldObject => {
      const filteredFields = fieldObject.fields.filter(
        field => !field.alias.includes('2028'),
      );
      fieldObject.fields = filteredFields;
    });

    const uniqueFields = [];
    const fieldNames = new Set();

    fieldsResponse.forEach(fieldObj => {
      fieldObj.fields.forEach(field => {
        if (!fieldNames.has(field.name)) {
          fieldNames.add(field.name);
          uniqueFields.push({
            group: fieldObj.name,
            title: field.alias,
            status: false,
            type: field.type,
            prefix: null,
            suffix: null,
            outfields: [field.name],
            serviceURL: fieldObj.serviceURL,
          });
        }
      });
    });

    return uniqueFields;
  }

  function updateSections(sectionKey, newBlocks) {
    const updatedSections = Sections.map(sec => {
      if (sec.key === sectionKey) {
        // Get existing blocks from sectionsJson
        const existingBlocks =
          sectionsJson.find(s => s.key === sectionKey)?.blocks || [];

        console.log({ existingBlocks });
        // Combine existing blocks, new blocks, and blocks from sectionsJson
        const combinedBlocks = [
          ...(sec.blocks || []).filter(block => block.status === true),
          ...newBlocks,
          ...existingBlocks.filter(
            block =>
              !sec.blocks.some(
                b => b.title === block.title && b.status === true,
              ),
          ),
        ];

        // Remove duplicates
        const uniqueBlocks = Array.from(
          new Set(combinedBlocks.map(JSON.stringify)),
        ).map(JSON.parse);

        sec.blocksFetched = true;
        return { ...sec, blocks: uniqueBlocks };
      }
      return sec;
    });

    setSections(updatedSections);
  }

  async function fetchMaps() {
    console.log('fetchMaps called');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/map/templates`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch maps for templates');
      }

      const data = await response.json();

      if (data?.status === true) {
        const fetchedMaps = data?.maps;
        const updatedSections = fetchedMaps.map((map, index) => {
          const sectionToUpdate = Sections.find(
            section => section.key === map.key,
          );
          if (sectionToUpdate) {
            return {
              ...sectionToUpdate,
              map: {
                ...map,
                image: map?.imageURL,
                status: sectionToUpdate?.map?.status ? true : false,
              },
            };
          } else {
            return {
              key: map.key,
              title: map.title,
              blocks: [],
              map: {
                ...map,
                image: map?.imageURL,
                status: false,
              },
            };
          }
        });

        console.log({ updatedSections });
        console.log({ check: data.maps });

        setSections(updatedSections);
        setMaps(data.maps);
        return updatedSections;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function adminCheck() {
    try {
      if (!user) throw new Error('No User Authenticated');
      if (!user?.Email) throw new Error('No User Email Available');
      console.log(user.Email);
      const response = await fetch(
        process.env.REACT_APP_NODE_URL + '/admin/check-admin',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: user.Email }),
        },
      );

      if (!response.ok) throw new Error('Failed to check admin status');

      const data = await response.json();
      console.log({ data });
      if (data.isAdmin === false) {
        console.log('User does not have admin privileges');
        return navigate('/run-reports');
      }
      console.log('User has admin privileges');
    } catch (error) {
      console.error(error);
      return navigate('/run-reports');
    } finally {
      setAdminLoader(false);
    }
  }

  useEffect(() => {
    adminCheck().then(() => {
      fetchMaps();
    });
  }, []);

  return (
    <ReportTemplatesContext.Provider
      value={{
        setCurrentStage,
        currentStage,
        newTemplateName,
        setNewTemplateName,
        setSections,
        Sections,
        lastStage,
        setLastStage,
        addBlockModal,
        setAddBlockModal,

        removeBlock,
        saveTemplate,
        loadTemplate,
        editing,
        setEditing,

        getBlocksFromArcGIS,
        setLoading,
        fetchMaps,

        Maps,

        updateBlockTitle,
        updatedBlockPrefixAndSuffix,
        getToken,
        fetchToken,
        fetchLayerUrlsAndTitles,
        fetchFields,
        updateSections,
        updateBlockValue,
        addCustomMapInfo,
      }}
    >
      {adminLoader === true ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {/* Header */}

          <Box
            sx={{
              paddingX: {
                md: '1.25rem',
                xs: '0',
              },
              marginBottom: '1.25rem',
            }}
          >
            <Header
              sideBarState={setSideBarState}
              pageTitle={
                {
                  templateTable: 'Templates',
                }[currentStage] || 'Add New Templates'
              }
            />
            <Box
              sx={{
                paddingX: '1rem',
                display: 'flex',
                alignItems: 'center',
                gap: '.15rem',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Lato',
                  fontSize: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 800,
                  lineHeight: '24px',
                  letterSpacing: '0.2px',
                  textAlign: 'left',
                  color: '#004852',
                  cursor: 'pointer',
                }}
                onClick={clearStates}
              >
                Template Table
                <GoBack />
              </Typography>
            </Box>
          </Box>

          {/* Main Content */}
          <Box
            sx={{
              paddingX: {
                md: '1.25rem',
                xs: '0',
              },
              display: 'flex',
              flexDirection: 'column',
              gap: '1.25rem',
            }}
          >
            {currentStage !== 'templateTable' && <ProgressIndicator />}
            {{
              templateTable: <TemplateTableSection />,
              createTemplate: <CreateTemplateSection />,
              customizeTemplate: <CustomizeTemplateSection />,
              saveAndFinish: <CustomizeTemplateSection />,
            }[currentStage] || <TemplateTableSection />}
          </Box>
        </Box>
      )}
    </ReportTemplatesContext.Provider>
  );
}
function ProgressIndicator() {
  const { currentStage, setCurrentStage, lastStage, editing } = useContext(
    ReportTemplatesContext,
  );

  const stages = ['createTemplate', 'customizeTemplate', 'saveAndFinish'];

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '3.6rem',
        paddingX: '2rem',
      }}
    >
      {/*
        4 Stages:
            1. Create Template
            2. Add Maps to Template // removed
            3. Customize Template
            4. Save & Finish
      */}

      {stages.map((stage, index) => (
        <>
          <Box
            key={stage + Date.now()}
            sx={{
              height: '3.6rem',
              width: '60%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem',

              border:
                stage === currentStage
                  ? '1px solid #4FA490'
                  : '1px dashed #DFDFDF',

              borderRadius: '1.25rem',
              cursor:
                editing === true
                  ? 'pointer'
                  : stages.indexOf(stage) <= stages.indexOf(lastStage)
                    ? 'pointer'
                    : 'default',
            }}
            onClick={() => {
              if (!editing) {
                if (stages.indexOf(stage) <= stages.indexOf(lastStage)) {
                  setCurrentStage(stage);
                }
              } else {
                setCurrentStage(stage);
              }
            }}
          >
            <Box
              sx={{
                height: '1.2rem',
                width: '1.2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid',
                borderColor: stage === currentStage ? '#4FA490' : '#DFDFDF',
                borderRadius: '50%',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Lato',
                  fontSize: '0.9rem',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '0.2px',
                  textAlign: 'center',
                  color: stage === currentStage ? '#212529' : '#DBDAD9',
                }}
              >
                {index + 1}
              </Typography>
            </Box>

            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '0.9rem',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '0.2px',
                textAlign: 'center',
                color: stage === currentStage ? '#212529' : '#DBDAD9',
              }}
            >
              {
                {
                  createTemplate: 'Create Template',
                  customizeTemplate: 'Customize Template',
                  saveAndFinish: 'Save & Finish',
                }[stage]
              }
            </Typography>
          </Box>
          {/* A straight line in the center */}
          {index !== stages.length - 1 && (
            <Box
              sx={{
                height: '0.1rem',
                width: '10%',
                backgroundColor: '#DFDFDF',
              }}
            ></Box>
          )}
        </>
      ))}
    </Box>
  );
}

// * Helper SVGs
function TickMark() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-check'
    >
      <path d='M20 6 9 17l-5-5' />
    </svg>
  );
}

function GoBack() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 24 24'
      fill='none'
      stroke='#004852'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-undo-2'
    >
      <path d='M9 14 4 9l5-5' />
      <path d='M4 9h10.5a5.5 5.5 0 0 1 5.5 5.5a5.5 5.5 0 0 1-5.5 5.5H11' />
    </svg>
  );
}

function RemoveBlockSVG() {
  const { currentStage } = useContext(ReportTemplatesContext);
  if (currentStage !== 'customizeTemplate') return null;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='#D32F2F'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-circle-minus'
    >
      <circle cx='12' cy='12' r='10' />
      <path d='M8 12h8' />
    </svg>
  );
}

function QuestionMarkSVG() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='#4FA490'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-circle-help'
    >
      <circle cx='12' cy='12' r='10' />
      <path d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3' />
      <path d='M12 17h.01' />
    </svg>
  );
}

function BotSVG() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='36'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-bot'
    >
      <path d='M12 8V4H8' />
      <rect width='16' height='12' x='4' y='8' rx='2' />
      <path d='M2 14h2' />
      <path d='M20 14h2' />
      <path d='M15 13v2' />
      <path d='M9 13v2' />
    </svg>
  );
}

// * Template Table Section
function TemplateTableSection() {
  const { setCurrentStage, Maps, Sections, setSections, fetchMaps } =
    useContext(ReportTemplatesContext);
  const [templates, setTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(true);

  async function getTemplates() {
    try {
      const user = JSON.parse(localStorage.getItem('mongodbUser'));
      if (!user) throw new Error('User not found in localstorage');

      const url =
        process.env.REACT_APP_NODE_URL + '/templates/' + user._id + '?option=1';
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await res.json();
      setTemplates(data);
      setTemplatesLoading(false);
    } catch (error) {
      console.error(error);
      setTemplatesLoading(false);
    }
  }

  async function reoraganizeSections() {
    if (Maps) {
      console.log('Maps Already Exist');
      const updatedSections = Maps.map((map, index) => {
        const sectionToUpdate = Sections.find(
          section => section.key === map.key,
        );
        if (sectionToUpdate) {
          return {
            ...sectionToUpdate,
            map: {
              ...map,
              image: map?.imageURL,
              status: false,
            },
          };
        } else {
          return {
            key: map.key,
            title: map.title,
            blocks: [],
            map: {
              ...map,
              image: map?.imageURL,
              status: false,
            },
          };
        }
      });
      setSections(updatedSections);
      return;
    } else {
      console.log("Maps Don't Exist. Fetching...");
      await fetchMaps();
      console.log('Maps fetched');
    }
  }

  useEffect(() => {
    getTemplates();
    reoraganizeSections();
  }, []);

  return (
    <Box
      sx={{
        paddingX: '1.25rem',
        minHeight: '30rem',
        border: '1px solid #EDF2F7',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Title Bar (with Button) */}
      <Box
        sx={{
          height: '3.6rem',
          paddingY: '0.4rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Name and Arrow Keys */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              fontWeight: 800,
              lineHeight: '24px',
              letterSpacing: '0.20000000298023224px',
              textAlign: 'left',
              color: '#004852',
            }}
          >
            Name
          </Typography>
          <Arrows />
        </Box>

        {/* Add New Button */}
        <Button
          variant='contained'
          startIcon={<AddCircleOutlineIcon />}
          sx={{
            height: '100%',
            width: '8.6rem',
            padding: '0.5rem 1.25rem 0.5rem 1.25rem',
            borderRadius: '0.75rem',
            backgroundColor: '#4FA490',
            '&:hover': {
              backgroundColor: '#4FA490',
            },
          }}
          onClick={async () => {
            setCurrentStage('createTemplate');
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '0.9rem',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.2px',
              textAlign: 'center',
              color: '#FFFFFF',
            }}
          >
            Add New
          </Typography>
        </Button>
      </Box>

      {/* Template Info */}
      <Box>
        {templatesLoading &&
          [...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant='rounded'
              sx={{
                height: '2.5rem',
                borderTop: '1px solid #EDF2F7',
                marginTop: '0.5rem',
              }}
            />
          ))}

        {templates.length === 0 && !templatesLoading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              padding: '2rem',
            }}
          >
            <BotSVG />
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '1rem',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '0.2px',
                textAlign: 'center',
                color: '#1A202C',
                marginTop: '1rem',
              }}
            >
              You don't have any templates
            </Typography>
          </Box>
        )}

        {templates.map((template, index) => (
          <TemplateRow
            key={template._id}
            template={template}
            setTemplates={setTemplates}
            templates={templates}
          />
        ))}
      </Box>
    </Box>
  );
}

function SliderButton({ isActive, onToggle }) {
  return (
    <ThemeProvider theme={theme}>
      <Button
        onClick={onToggle}
        aria-pressed={isActive}
        sx={{
          position: 'relative',
          width: '6rem',
          height: '2rem',
          backgroundColor: 'white',
          borderRadius: 32,
          padding: 1,
          boxShadow: 3,
          overflow: 'hidden',
          '&:hover': {
            backgroundColor: 'white',
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
          },
        }}
      >
        <motion.div
          animate={{
            x: isActive ? '100%' : '0%',
          }}
          transition={{
            type: 'spring',
            stiffness: 700,
            damping: 30,
          }}
          style={{
            position: 'absolute',
            left: 4,
            top: 4,
            width: 'calc(50% - 4px)',
            height: 'calc(100% - 8px)',
            borderRadius: 28,
            background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          }}
        />
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            gap: '1rem',
            px: 3,
          }}
        >
          <Box
            sx={{
              color: isActive ? 'text.secondary' : 'white',
              transition: 'color 0.3s',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GlobeLock />
          </Box>
          <Box
            sx={{
              color: isActive ? 'white' : 'text.secondary',
              transition: 'color 0.3s',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Globe />
          </Box>
        </Box>
      </Button>
    </ThemeProvider>
  );
}
function TemplateRow({ template, setTemplates, templates }) {
  const { loadTemplate, setEditing } = useContext(ReportTemplatesContext);
  const styles = {
    buttonStyle: {
      border: '1px solid',
      borderRadius: '0.5rem',
      width: '5.5rem',
      fontFamily: 'Lato',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '0.2px',
      textAlign: 'right',
    },
  };

  async function deleteTemplate(templateID) {
    try {
      const url = process.env.REACT_APP_NODE_URL + '/templates/' + templateID;
      const res = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!res.ok) throw new Error('Error in Deleting Template Endpoint');

      const updatedTemplates = templates.filter(
        template => template._id !== templateID,
      );
      setTemplates(updatedTemplates);
    } catch (error) {
      console.error(error);
    }
  }

  async function toggleVisibility(templateID, isDefault) {
    try {
      const url = process.env.REACT_APP_NODE_URL + '/templates/make-default';
      const res = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ templateID, isDefault }),
      });

      if (!res.ok) throw new Error('Error in Toggling Visibility');
      const updatedTemplates = templates.map(template =>
        template._id === templateID ? { ...template, isDefault } : template,
      );
      setTemplates(updatedTemplates);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '3.6rem',
        borderTop: '1px solid #EDF2F7',
      }}
    >
      {/* Title */}
      <Box
        sx={[
          {
            fontFamily: 'Lato',
            fontSize: '1rem',
            padding: '0.375rem 0.5rem 0.375rem 0.5rem',
            borderRadius: '0.5rem',
          },

          template?.theme
            ? template.theme
            : getColor(Math.floor(Math.random() * 5)),
        ]}
      >
        {template.templateName}
      </Box>

      {/* Buttons */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '2.2rem',
        }}
      >
        <Button
          sx={[
            styles.buttonStyle,
            {
              borderColor: '#00485259',
              color: '#1A202C',
            },
          ]}
          onClick={async () => {
            setEditing(true);
            loadTemplate(template);
          }}
        >
          Edit
        </Button>
        <Button
          sx={[
            styles.buttonStyle,
            {
              borderColor: '#FF474759',
              color: '#FF4747',
            },
          ]}
          onClick={() => {
            deleteTemplate(template._id);
          }}
        >
          Delete
        </Button>
        <SliderButton
          isActive={template.isDefault}
          onToggle={() => toggleVisibility(template._id, !template.isDefault)}
        />
      </Box>
    </Box>
  );
}

// * Create Template Section
function CreateTemplateSection() {
  const {
    setNewTemplateName,
    setCurrentStage,
    newTemplateName,
    lastStage,
    setLastStage,
    editing,
  } = useContext(ReportTemplatesContext);
  const [error, setError] = useState(false);

  async function checkTemplateName(templateName) {
    try {
      const user = JSON.parse(localStorage.getItem('mongodbUser'));
      const url = `${process.env.REACT_APP_NODE_URL}/templates/check-name?templateName=${templateName}&userID=${user._id}`;
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!res.ok) throw new Error('Error checking template name');

      const data = await res.json();
      return data.exists;
    } catch (error) {
      console.error('Error checking template name:', error);
      return false;
    }
  }

  async function handleContinue(e) {
    e.preventDefault();
    if (!newTemplateName) {
      setError(true);
      return;
    }

    if (!editing) {
      const nameCheck = await checkTemplateName(newTemplateName);
      if (nameCheck === true) {
        setError(true);
        alert('Template name already used. Please choose another.');
        return;
      }
    }

    if (lastStage === 'createTemplate') {
      setLastStage('customizeTemplate');
    }
    setCurrentStage('customizeTemplate');
  }

  return (
    <Box
      sx={{
        paddingX: '2rem',
        paddingY: '1.25rem',
        border: '1px solid #EDF2F7',
        borderRadius: '1rem',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Lato',
          fontSize: '1rem',
          fontWeight: 800,
          lineHeight: '24px',
          letterSpacing: '0.2px',
          textAlign: 'left',
          color: '#004852',
        }}
      >
        Name your Template
      </Typography>

      <input
        type='text'
        id='templateName'
        name='templateName'
        placeholder='Enter the name of your template'
        style={{
          width: '32rem',
          height: '2.75rem',
          marginTop: '1rem',
          padding: '0.5rem',
          border: error ? '1px solid #FF4747' : '1px solid #DFDFDF',
          borderRadius: '0.75rem',
        }}
        value={newTemplateName}
        onChange={e => {
          setError(false);
          setNewTemplateName(e.target.value);
        }}
      />

      <Divider sx={{ marginY: '1.25rem' }} />

      <Button
        variant='contained'
        sx={{
          width: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
          height: '3.5rem',
          backgroundColor: '#4FA490',
          color: '#FFFFFF',
          padding: '0.5rem 1.25rem 0.5rem 1.25rem',
          borderRadius: '0.75rem',
          '&:hover': {
            backgroundColor: '#4FA490',
          },
        }}
        onClick={handleContinue}
      >
        <Box
          sx={{
            height: '0.7rem',
            width: '0.7rem',
            borderRight: '2px solid #FFFFFF',
            borderBottom: '2px solid #FFFFFF',
            transform: 'rotate(-45deg)',
            marginRight: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></Box>

        <Typography
          sx={{
            fontFamily: 'Lato',
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.2px',
            textAlign: 'center',
          }}
        >
          Continue
        </Typography>
      </Button>
    </Box>
  );
}

function AddMapsToTemplateSection({ isOpen = true, onClose }) {
  const { Maps } = useContext(ReportTemplatesContext);
  const [searchResults, setSearchResults] = useState(Maps || []);
  const [searchQuery, setSearchQuery] = useState('');
  const debounceTimer = useRef(null);

  useEffect(() => {
    const dialog = document.getElementById('add-maps-modal');
    if (isOpen) {
      dialog.showModal();
    } else {
      dialog.close();
    }
  }, [isOpen]);

  async function searchMaps(query) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/map/search?query=${encodeURIComponent(query)}`,
        { method: 'GET', headers: { 'Content-Type': 'application/json' } },
      );
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      return data.status ? data.maps : [];
    } catch (error) {
      console.error('Error searching maps:', error);
      return [];
    }
  }

  useEffect(() => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(async () => {
      setSearchResults(
        !searchQuery
          ? Maps
          : (await searchMaps(searchQuery)).map(mapID =>
              Maps.find(m => m._id === mapID._id),
            ),
      );
    }, 500);
    return () => debounceTimer.current && clearTimeout(debounceTimer.current);
  }, [searchQuery, Maps]);

  const handleSearch = e => setSearchQuery(e.target.value);

  const groupedMaps = searchResults.reduce((acc, map) => {
    (map.tags || []).forEach(tag => {
      const normalizedTag =
        tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase();
      if (!acc[normalizedTag]) acc[normalizedTag] = [];
      acc[normalizedTag].push(map);
    });
    return acc;
  }, {});

  return (
    <dialog id='add-maps-modal'>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
        {/* Heading and Button to Add Maps */}
        <Box
          sx={{
            paddingX: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              fontWeight: 800,
              color: '#1A202C',
            }}
          >
            Add Maps to your Template
          </Typography>
          <Button
            onClick={onClose}
            sx={{
              alignSelf: 'center',
              backgroundColor: '#4FA490',
              color: '#FFFFFF',
              padding: '0.5rem 1.25rem',
              borderRadius: '0.75rem',
              '&:hover': {
                backgroundColor: '#3A8B7A',
              },
              fontFamily: 'Lato',
              fontSize: '1rem',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.2px',
              textAlign: 'center',
            }}
          >
            Close
          </Button>
        </Box>

        {/* Search Box and Drop Down */}
        <Box
          sx={{
            paddingX: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {/* Search Box */}
          <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <span
              style={{
                position: 'absolute',
                top: '50%',
                left: '1rem',
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='lucide lucide-search'
              >
                <circle cx='11' cy='11' r='8' />
                <path d='m21 21-4.3-4.3' />
              </svg>
            </span>
            <input
              placeholder='Search for Maps & Data'
              style={{
                width: '56rem',
                height: '3rem',
                border: '1px solid #DFDFDF',
                borderRadius: '0.75rem',
                paddingLeft: '3.5rem', // adjust this to prevent text from overlapping the icon

                fontFamily: 'Lato',
                fontSize: '0.85rem',
                fontWeight: 800,
                lineHeight: '21px',
                letterSpacing: '0.2px',
                textAlign: 'left',
                color: '#212529',

                '::placeholder': {
                  color: '#212529',
                },
              }}
              onChange={handleSearch}
            />
          </Box>
          <select
            style={{
              width: '100%',
              height: '3.3rem',
              border: '1px solid #DFDFDF',
              borderRadius: '0.75rem',
              paddingLeft: '1rem', // adjust this to prevent text from overlapping the icon
              backgroundColor: '#FFFFFF',
              appearance: 'none',

              fontFamily: 'Lato',
              fontSize: '0.85rem',
              fontWeight: 800,
              lineHeight: '21px',
              letterSpacing: '0.2px',
              textAlign: 'left',
              color: '#212529',
            }}
            onChange={e =>
              setSearchResults(
                Maps.filter(map => map.tags.includes(e.target.value)),
              )
            }
          >
            <option value=''>Category</option>
            {[...new Set(Maps.flatMap(map => map.tags || []))]
              .filter(tag => tag)
              .map(tag => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
          </select>
        </Box>

        {/* Main Content - Maps */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.75rem',
            border: '1px solid #DFDFDF',
            padding: '1rem',
          }}
        >
          {Object.keys(groupedMaps).map((category, index) => (
            <Box key={index}>
              <Typography
                sx={{
                  fontFamily: 'Lato',
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  color: '#1A202C',
                }}
              >
                {category}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                {groupedMaps[category].map((map, mapIndex) => (
                  <MapBox
                    key={mapIndex}
                    sectionTitle={map.title}
                    mapImage={map.imageURL}
                    mapDescription={map.description}
                    sectionKey={map.key}
                  />
                ))}
              </Box>
            </Box>
          ))}
          {searchResults.length === 0 && (
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '1rem',
                fontWeight: 700,
                textAlign: 'center',
                color: '#1A202C',
              }}
            >
              No results found
            </Typography>
          )}
        </Box>
      </Box>
    </dialog>
  );
}

function MapBox({ sectionKey, mapImage, sectionTitle, mapDescription }) {
  const { Sections, setSections, Maps } = useContext(ReportTemplatesContext);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  function handleSectionAddition(section) {
    console.log({ chosenSection: section });

    const newSection = {
      ...section,
      key: generateUniqueKey(section.key),
      status: true,
      blocks: [],
    };

    delete newSection.mapBlock;

    if (newSection.map) {
      newSection.map = {
        ...newSection.map,
        status: true,
      };
    }

    setSections(prevSections => [...prevSections, newSection]);
    setAlertMessage(
      `You have added ${section.title} as a new section to your template.`,
    );
    setShowAlert(true);
  }

  function generateUniqueKey(baseKey) {
    const existingKeys = Sections.map(s => s.key);
    let newKey = baseKey;
    let counter = 1;

    while (existingKeys.includes(newKey)) {
      newKey = `${counter}_${baseKey}`;
      counter++;
    }

    return newKey;
  }

  function handleMapClick() {
    const selectedMap = Maps.find(map => map.key === sectionKey);
    const selectedSection = Sections.find(
      section => section.key === sectionKey,
    );
    handleSectionAddition({
      key: sectionKey,
      title: sectionTitle,
      map: {
        ...selectedMap,
        status: true,
      },
      blocks: selectedSection.blocks.map(block => ({
        ...block,
        status: false,
      })),
    });
  }

  return (
    <>
      <Box
        key={sectionKey}
        sx={{
          alignSelf: 'center',
          '@media (max-width: 600px)': {
            width: '100%',
          },
          '@media only screen and (min-width: 600px) and (max-width: 1023px)': {
            width: '49%',
          },
          '@media only screen and (min-width: 1024px)': {
            width: '32%',
          },
          borderRadius: '1.21144rem',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          marginY: '1rem',
          cursor: 'pointer',
          boxShadow: '5px 20px 30px 0px rgba(229, 229, 229,0.7)',
        }}
        onClick={handleMapClick}
      >
        {/* Image */}
        <Box
          sx={{
            width: '100%',
            height: '150px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {mapImage ? (
            <img
              src={mapImage || ''}
              alt=''
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                transform: 'scale(1.8)',
              }}
            />
          ) : (
            <div
              style={{
                width: '100%',
                height: '200px', // You can adjust the height as needed
                backgroundColor: 'gray',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '16px',
              }}
            >
              No Map Available
            </div>
          )}
        </Box>

        {/* Title and Description */}
        <Box
          sx={{
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          {/* Title */}
          <Typography
            variant='h6'
            component='h3'
            textAlign={'center'}
            sx={{
              fontFamily: '"Lato", sans-serif',
              fontSize: '1.25rem',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '140%',
              textAlign: 'left',
              color: '#323142',
            }}
          >
            {sectionTitle}
          </Typography>

          {/* Description */}
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              fontWeight: 500,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {mapDescription}
          </Typography>
        </Box>
      </Box>

      {showAlert && (
        <Alert
          title='Added Map'
          message={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
    </>
  );
}

// * Customize Template Section
function CustomizeTemplateSection() {
  const {
    setCurrentStage,
    setLastStage,
    lastStage,
    currentStage,
    saveTemplate,
    Sections,
  } = useContext(ReportTemplatesContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        border: '1px solid #EDF2F7',
        borderRadius: '1rem',
        paddingX: '1.25rem',
        paddingY: '1.25rem',
        marginX: '1rem',
      }}
    >
      {/* Heading and Button */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Lato',
            fontSize: '1rem',
            fontWeight: 800,
            lineHeight: '24px',
            letterSpacing: '0.2px',
            textAlign: 'left',
            color: '#1A202C',
          }}
        >
          Select Data Blocks To Add To Your Maps
        </Typography>

        <Button
          variant='contained'
          sx={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '3.5rem',
            backgroundColor: '#4FA490',
            color: '#FFFFFF',
            padding: '0.5rem 1.25rem 0.5rem 1.25rem',
            borderRadius: '0.75rem',
            '&:hover': {
              backgroundColor: '#4FA490',
            },
          }}
          startIcon={<TickMark />}
          onClick={() => {
            if (currentStage === 'customizeTemplate') {
              const isBlockSelected = Sections.some(
                section =>
                  section.status &&
                  (section.key === 'rent' ||
                    section.blocks.some(block => block.status)),
              );

              if (!isBlockSelected) {
                alert('At least select one Data Block');
                return;
              }

              setCurrentStage('saveAndFinish');
              if (lastStage === 'customizeTemplate') {
                setLastStage('saveAndFinish');
              }
            } else if (currentStage === 'saveAndFinish') {
              saveTemplate();
            }
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.2px',
              textAlign: 'center',
            }}
          >
            {currentStage === 'customizeTemplate'
              ? 'Save Data Selection'
              : 'Save Report Template'}
          </Typography>
        </Button>
      </Box>

      {/* Report Section */}
      <ReportSection />
    </Box>
  );
}

function ReportSection() {
  const { addBlockModal, Sections, fetchToken, editing } = useContext(
    ReportTemplatesContext,
  );
  const [activeSection, setActiveSection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [token, setToken] = useState(null);
  const [symbolizationModal, setSymbolizationModal] = useState(() => {
    const initialState = {};
    Sections.forEach(section => {
      initialState[section.key] = false;
    });
    return initialState;
  });
  const [defaultMapFlag, setDefaultMapFlag] = useState(() => {
    const initialState = {};
    Sections.forEach(section => {
      initialState[section.key] =
        editing === true ? (section?.mapBlock ? false : true) : false;
    });
    return initialState;
  });
  const [customSymbolizationBlock, setCustomSymbolizationBlock] = useState(
    () => {
      const initialState = {};
      Sections.forEach(section => {
        initialState[section.key] =
          editing === true
            ? section?.mapBlock
              ? section.mapBlock
              : null
            : null;
      });
      return initialState;
    },
  );

  const [addSectionModal, setAddSectionModal] = useState(false);

  const sectionRefs = useRef([]);
  function renderBlock(block, index, sectionKey) {
    switch (block.title) {
      // Demographics
      case 'Nearest Groups':
        return <NearestGroupsBlock key={index} />;
      case 'Grade':
        return <GradeBlock key={index} />;
      case 'Demographic Group':
        return <DemographicGroupBlock key={index} />;
      case 'Who We Are':
        return <WhoWeAreBlock key={index} />;

      // Crime

      case 'Overall Crime Index Score': {
        return (
          <NationalAverageCrimeIndex
            title={block?.title}
            sectionKey={sectionKey}
          />
        );
      }

      case 'Property Crime Index Score': {
        return (
          <NationalAverageCrimeIndex
            title={block?.title}
            sectionKey={sectionKey}
          />
        );
      }

      // Expansion
      case 'Block':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );
      case 'Tract':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );
      case 'Zip':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );
      case 'County':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );
      case 'State':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );
      case 'National':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );

      // Rent
      case 'Average':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );
      case 'Median':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );
      case '25 ~ Percentile':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );
      case '75 ~ Percentile':
        return (
          <GenericBlock
            sectionKey={sectionKey}
            key={index}
            title={block.title}
          />
        );

      // Neighborhood:
      case 'Nearest Stores':
        return <NearestStoresBlock />;

      // HPI:
      case 'Price Appreciation Since 2011':
        return (
          <GenericAppreciationBlock title={'Price Appreciation Since 2011'} />
        );

      case 'Background Rate of Appreciation (1990 - present)':
        return (
          <GenericAppreciationBlock
            title={'Background Rate of Appreciation (1990 - present)'}
          />
        );

      case 'HPI TRACT/ZIP INDEX':
        return <HousePriceIndexGraphBlock />;

      // Schools
      case 'School District Name':
        return <SchoolDistrictNameBlock />;

      case 'Nearest 3 Schools':
        return <NearestThreeSchoolsBlock />;

      // Income
      case 'Median Household Income':
        return (
          <GenericIncomeBlock
            title='Median Household Income'
            sectionKey='income'
            blocks={[
              ['Block', 'Tract', 'Zip'],
              ['County', 'State', 'National'],
            ]}
          />
        );

      case 'Median Home Value':
        return (
          <GenericIncomeBlock
            title='Median Home Value'
            sectionKey='income'
            blocks={[['Block', 'Tract', 'Zip']]}
          />
        );

      case 'Annual Forecasted Median Income Growth':
        return (
          <GenericIncomeBlock
            title='Annual Forecasted Median Income Growth'
            sectionKey='annual_income'
            blocks={[['Tract', 'Zip']]}
          />
        );

      // MSA
      case 'Stat Block':
        return <StatsMSABlock />;

      case 'Business Diversification Index':
        return (
          <DiversificationBlock title={'Business Diversification Index'} />
        );

      case 'Employers Diversification Index':
        return (
          <DiversificationBlock title={'Employers Diversification Index'} />
        );
      case 'Top 5 Businesses by Sector':
        return <Top5Block title={'Top 5 Businesses by Sector'} />;

      case 'Top 5 Employers by Sector':
        return <Top5Block title={'Top 5 Employers by Sector'} />;

      default:
        return <DefaultBlock sectionKey={sectionKey} block={block} />;
    }
  }

  useEffect(() => {
    const observerOptions = {
      root: null, // null makes it observe the entire viewport
      rootMargin: '0px',
      threshold: [0.5, 1], // 50% of the section must be visible
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.getAttribute('id'));
        }
      });
    }, observerOptions);

    sectionRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      sectionRefs.current.forEach(ref => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  useEffect(() => {
    const getToken = async () => {
      const fetchedToken = await fetchToken();
      setToken(fetchedToken);
    };

    getToken();
  }, []);

  const iconStyle = {
    height: '2.25rem',
    width: '2.25rem',
  };

  useEffect(() => {
    console.log({ defaultMapFlag });
    console.log({ customSymbolizationBlock });
  }, [defaultMapFlag, customSymbolizationBlock]);

  return (
    <>
      <Sidebar sectionRefs={sectionRefs} activeSection={activeSection} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.25rem',
        }}
      >
        {/* Add Block Modal */}
        {addBlockModal?.status && <AddBlockModal />}

        {Sections.filter(section => section.status === true).map(
          (section, index) =>
            section.status && (
              <Box
                key={index}
                ref={el => (sectionRefs.current[index] = el)} // Assign the ref to the section
                id={section.key}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <ReportSectionHeader
                  icon={
                    <>
                      {
                        {
                          demographics: <DemoIconDark style={iconStyle} />,

                          income: <IncomeIconDark style={iconStyle} />,

                          hpi: <HPIIconDark style={iconStyle} />,
                          schools: <SchoolsIconDark style={iconStyle} />,
                          crime: <CrimeIconDark style={iconStyle} />,
                          expansion: <ExpansionIconDark style={iconStyle} />,
                          rent: <RentIconDark style={iconStyle} />,
                          neighborhood: (
                            <NeighborhoodIconDark style={iconStyle} />
                          ),
                          msa: <MSAIconDark style={iconStyle} />,
                          ai_analysis: <AiAnalysisDark style={iconStyle} />,
                        }[section.key]
                      }
                    </>
                  }
                  title={section.title}
                  sectionKey={section.key}
                />

                <InfoLabel
                  info={
                    'Default Map is used in case of no custom symbolization*'
                  }
                />
                {section.map.status}
                {section.status}
                {defaultMapFlag[section.key]}
                {/* {symbolizationModal[section.key]} */}
                {(section.map.status === true || section.status === true) &&
                  section.map.webmapID &&
                  token &&
                  (defaultMapFlag[section.key] === true ? (
                    <>
                      <Button
                        sx={{
                          backgroundColor: '#F9F9F9',
                          color: '#004852',
                          border: '2px solid #004852',
                          height: '2.75rem',
                          width: '8rem',
                          padding: '0.5rem 1rem',
                          borderRadius: '0.75rem',
                          marginRight: '1rem',
                          '&:hover': {
                            backgroundColor: '#F0F0F0',
                          },
                        }}
                        startIcon={<Undo2 />}
                        onClick={() => {
                          setDefaultMapFlag(prev => {
                            return { ...prev, [section.key]: false };
                          });
                          setCustomSymbolizationBlock(prev => {
                            return { ...prev, [section.key]: null };
                          });
                          setSymbolizationModal(prev => {
                            return { ...prev, [section.key]: true };
                          });
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '0.9rem',
                            fontWeight: 700,
                          }}
                        >
                          Change
                        </Typography>
                      </Button>

                      <LiveMap
                        webmapID={section?.map?.webmapID}
                        token={token}
                        defaultSettings={true}
                      />
                    </>
                  ) : customSymbolizationBlock[section.key] ? (
                    <>
                      <Button
                        sx={{
                          backgroundColor: '#F9F9F9',
                          color: '#004852',
                          border: '2px solid #004852',
                          height: '2.75rem',
                          width: '8rem',
                          padding: '0.5rem 1rem',
                          borderRadius: '0.75rem',
                          marginRight: '1rem',
                          '&:hover': {
                            backgroundColor: '#F0F0F0',
                          },
                        }}
                        startIcon={<Undo2 />}
                        onClick={() => {
                          setDefaultMapFlag(prev => {
                            return { ...prev, [section.key]: false };
                          });
                          setCustomSymbolizationBlock(prev => {
                            return { ...prev, [section.key]: null };
                          });
                          setSymbolizationModal(prev => {
                            return { ...prev, [section.key]: true };
                          });
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '0.9rem',
                            fontWeight: 700,
                          }}
                        >
                          Change
                        </Typography>
                      </Button>
                      <LiveMap
                        webmapID={section?.map?.webmapID}
                        token={token}
                        defaultSettings={false}
                        selectedBlock={customSymbolizationBlock[section.key]}
                      />
                    </>
                  ) : (
                    <Box
                      sx={{
                        flex: 1,
                        backgroundColor: '#F9F9F9',
                        paddingY: '1.25rem',
                        paddingX: '1rem',
                        borderRadius: '1rem',
                        display: 'flex',
                        gap: '1rem',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Lato',
                          lineHeight: '1.5rem',
                          textAlign: 'center',
                          fontSize: '1.1rem',
                          fontWeight: 700,
                          color: '#39585C',
                        }}
                      >
                        Custom Symbolization
                      </Typography>
                      <Button
                        sx={{
                          boxSizing: 'border-box',

                          backgroundColor: '#39585C',
                          color: '#FFFFFF',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px',
                          borderRadius: '1.2rem',
                          padding: '16px',

                          cursor: 'pointer',

                          '&:hover': {
                            backgroundColor: '#39585C',
                            color: '#FFFFFF',
                          },
                        }}
                        onClick={() => {
                          setSymbolizationModal(prev => {
                            return { ...prev, [section.key]: true };
                          });
                        }}
                      >
                        Set Symbolization Modal
                      </Button>
                    </Box>
                  ))}

                {symbolizationModal[section.key] && (
                  <CustomSymbolizationModal
                    Section={section}
                    setModal={() => {
                      setSymbolizationModal(prev => {
                        return { ...prev, [section.key]: false };
                      });
                    }}
                    setDefaultMapFlag={() => {
                      console.log({ check: section.key });
                      setDefaultMapFlag(prev => {
                        return { ...prev, [section.key]: true };
                      });
                    }}
                    defaultMapFlag={defaultMapFlag[section.key]}
                    setBlock={block => {
                      console.log({ check: section.key });

                      setCustomSymbolizationBlock(prev => {
                        return { ...prev, [section.key]: block };
                      });
                    }}
                  />
                )}

                {section.key !== 'rent' && (
                  <InfoLabel
                    info={'Up to 9 data blocks in 3 columns can be added*'}
                  />
                )}

                {section.blocks.some(block => block.status) && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '3rem',
                      marginBottom: '.5rem',
                    }}
                  >
                    <BlockNameHeading
                      title={
                        {
                          expansion: 'Annual Forecasted Population Growth',
                          crime: 'National Average Crime Index',
                          msa: 'Metropolitan Statistical Area',
                          rent: '999 9th St Nw, Washington, DC 20001',
                        }[section.key]
                      }
                    />
                  </Box>
                )}

                {
                  // FOR HPI HEADER
                  section.key === 'hpi' &&
                    section.blocks.some(
                      block =>
                        block.status &&
                        (block.title === 'Price Appreciation Since 2011' ||
                          block.title ===
                            'Background Rate of Appreciation (1990 - present)'),
                    ) && <HousePriceIndexHeader />
                }

                {section.key !== 'rent' && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '.75rem',
                    }}
                  >
                    {/* Row 1 */}
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '.5rem',
                        flexWrap: 'wrap',
                      }}
                    >
                      {section.blocks
                        .filter(block => block.status === true)
                        .slice(0, 3)
                        .map((block, index) => {
                          return renderBlock(block, index, section.key);
                        })}
                      {section.blocks.filter(block => block.status === true)
                        .length < 3 && (
                        <AddDataBlock sectionKey={section.key} />
                      )}
                    </Box>

                    {/* Row 2 */}
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '.5rem',
                        flexWrap: 'wrap',
                      }}
                    >
                      {section.blocks
                        .filter(block => block.status === true)
                        .slice(3, 6)
                        .map((block, index) => {
                          return renderBlock(block, index, section.key);
                        })}
                      {section.blocks.filter(block => block.status === true)
                        .length >= 3 &&
                        section.blocks.filter(block => block.status === true)
                          .length < 6 && (
                          <AddDataBlock sectionKey={section.key} />
                        )}
                    </Box>

                    {/* Row 3 */}
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '.5rem',
                        flexWrap: 'wrap',
                      }}
                    >
                      {section.blocks
                        .filter(block => block.status === true)
                        .slice(6, 9)
                        .map((block, index) => {
                          return renderBlock(block, index, section.key);
                        })}
                      {section.blocks.filter(block => block.status === true)
                        .length >= 6 &&
                        section.blocks.filter(block => block.status === true)
                          .length < 9 && (
                          <AddDataBlock sectionKey={section.key} />
                        )}
                    </Box>
                  </Box>
                )}
              </Box>
            ),
        )}

        <Box
          sx={{
            flex: 1,
            backgroundColor: '#F9F9F9',
            paddingY: '1.25rem',
            paddingX: '1rem',
            borderRadius: '1rem',
            display: 'flex',
            gap: '1rem',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{
              boxSizing: 'border-box',

              backgroundColor: '#39585C',
              color: '#FFFFFF',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              borderRadius: '1.2rem',
              padding: '16px',

              cursor: 'pointer',

              '&:hover': {
                backgroundColor: '#39585C',
                color: '#FFFFFF',
              },
            }}
            onClick={() => setIsModalOpen(true)}
          >
            Add New Section
          </Button>
        </Box>

        <AddMapsToTemplateSection
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </Box>
    </>
  );
}

function Sidebar({ sectionRefs, activeSection }) {
  const { Sections } = useContext(ReportTemplatesContext);
  const [selectedSection, setSelectedSection] = useState(
    Sections.filter(section => section.status === true)[0]?.key,
  );

  // Filter sections with status true
  const activeSections = Sections.filter(section => section.status);

  const styles = {
    selected: {
      backgroundColor: '#39585C',
      color: '#FFFFFF',
    },

    unselected: {
      color: '#39585C',
      fontWeight: 400,
      fontStyle: 'normal',
    },

    itemBoxStyle: {
      display: 'flex',
      height: '1.1rem',
      padding: '1rem',
      alignItems: 'center',
      gap: '0.75rem',
      alignSelf: 'stretch',
      borderRadius: '0.75rem',
    },

    itemTextStyle: {
      fontFamily: '"Lato", sans-serif',
      fontSize: {
        xs: '0.875rem',
        sm: '.75rem',
        lg: '0.875rem',
      },
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '150%',
      letterSpacing: '0.0125rem',
    },

    upperBoxInnerStyle: {
      boxSizing: 'border-box',
      backgroundColor: '#4FA490',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.75rem',
      borderRadius: '1rem',
      padding: '0.75rem 0.2rem 0.75rem 0.2rem',
      cursor: 'pointer',
    },

    upperBoxTypography: {
      fontFamily: '"Lato", sans-serif',
      fontSize: {
        sm: '16px',
        xs: '0.8rem',
      },
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.2px',
      textAlign: 'center',
      color: '#FFFFFF',
    },

    selectedBox: {
      backgroundColor: '#39585C',
    },
    selectedText: {
      color: '#FFFFFF',
      fontWeight: 800,
      textAlign: 'left',

      fontSize: {
        sm: '14px',
        xs: '0.8rem',
      },
    },
    unselectedText: {
      color: '#39585C',
      fontWeight: 400,
      textAlign: 'left',
      fontSize: {
        sm: '14px',
        xs: '0.8rem',
      },
    },
    unselectedBox: {
      backgroundColor: '#F9F9F9',
    },
    iconStyle: {
      width: '1.5rem',
      height: '1.5rem',
    },
    lowerBoxInnerStyle: {
      boxSizing: 'border-box',

      backgroundColor: '#4FA490',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      borderRadius: '1.2rem',
      padding: '16px',

      cursor: 'pointer',
    },
  };

  const handleClick = index => {
    console.log({ click: activeSections[index].key });
    setSelectedSection(activeSections[index].key);

    sectionRefs.current[index]?.scrollIntoView({});
  };

  useEffect(() => {
    if (activeSection) {
      setSelectedSection(activeSection);
    }
  }, [activeSection]);
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        overflowY: 'auto',
        width: '17rem',
        backgroundColor: '#F9F9F9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        zIndex: 2,
      }}
    >
      <Box
        sx={{
          marginTop: '13.5rem',
          marginBottom: '5rem',
          width: '85%',
        }}
      >
        {/* Scrollable content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          {activeSections.map((section, index) => (
            <Box
              key={section.key}
              sx={[
                styles.lowerBoxInnerStyle,
                selectedSection === section.key
                  ? styles.selectedBox
                  : styles.unselectedBox,
              ]}
              onClick={() => handleClick(index)}
            >
              <Box
                sx={{
                  width: '1.5rem',
                  height: '1.5rem',
                }}
              >
                {
                  {
                    demographics:
                      selectedSection === section.key ? (
                        <DemoIconLight style={styles.iconStyle} />
                      ) : (
                        <DemoIconDark style={styles.iconStyle} />
                      ),
                    income:
                      selectedSection === section.key ? (
                        <IncomeIconLight style={styles.iconStyle} />
                      ) : (
                        <IncomeIconDark style={styles.iconStyle} />
                      ),
                    hpi:
                      selectedSection === section.key ? (
                        <HPIIconLight style={styles.iconStyle} />
                      ) : (
                        <HPIIconDark style={styles.iconStyle} />
                      ),
                    schools:
                      selectedSection === section.key ? (
                        <SchoolsIconLight style={styles.iconStyle} />
                      ) : (
                        <SchoolsIconDark style={styles.iconStyle} />
                      ),
                    crime:
                      selectedSection === section.key ? (
                        <CrimeIconLight style={styles.iconStyle} />
                      ) : (
                        <CrimeIconDark style={styles.iconStyle} />
                      ),
                    expansion:
                      selectedSection === section.key ? (
                        <ExpansionIconLight style={styles.iconStyle} />
                      ) : (
                        <ExpansionIconDark style={styles.iconStyle} />
                      ),
                    rent:
                      selectedSection === section.key ? (
                        <RentIconLight style={styles.iconStyle} />
                      ) : (
                        <RentIconDark style={styles.iconStyle} />
                      ),
                    neighborhood:
                      selectedSection === section.key ? (
                        <NeighborhoodIconLight style={styles.iconStyle} />
                      ) : (
                        <NeighborhoodIconDark style={styles.iconStyle} />
                      ),
                    msa:
                      selectedSection === section.key ? (
                        <MSAIconLight style={styles.iconStyle} />
                      ) : (
                        <MSAIconDark style={styles.iconStyle} />
                      ),
                    ai_analysis:
                      selectedSection === section.key ? (
                        <AiAnalysisLight style={styles.iconStyle} />
                      ) : (
                        <AiAnalysisDark style={styles.iconStyle} />
                      ),
                  }[section.key]
                }
              </Box>

              <Typography
                sx={
                  selectedSection === section.key
                    ? styles.selectedText
                    : styles.unselectedText
                }
              >
                {section.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function AddDataBlock({ sectionKey }) {
  const { setAddBlockModal, currentStage } = useContext(ReportTemplatesContext);
  if (currentStage !== 'customizeTemplate') return null;
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '13rem',
        alignItems: 'center',
        gap: '0.5rem',
        cursor: 'pointer',
      }}
      onClick={() => {
        setAddBlockModal({
          status: true,
          callingSection: sectionKey,
        });
      }}
    >
      <Typography>Add Data Block</Typography>

      <AddCircleOutlineIcon
        sx={{
          color: '#4FA490',
        }}
      />
    </Box>
  );
}

function ReportSectionHeader({ icon, title, sectionKey }) {
  const { currentStage, Sections, setSections } = useContext(
    ReportTemplatesContext,
  );
  const [isEditing, setIsEditing] = useState(false);
  const [editableTitle, setEditableTitle] = useState(null);

  const handleTitleClick = () => {
    if (currentStage === 'customizeTemplate') {
      setIsEditing(true);
    }
  };

  const handleInputChange = event => {
    setEditableTitle(event.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    // Update the section title in the context
    const updatedSections = Sections.map(section => {
      if (section.key === sectionKey) {
        section.title = editableTitle;
      }
      return section;
    });
    setSections(updatedSections);
  };

  useEffect(() => {
    setEditableTitle(title);
  }, [title]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '1rem',
      }}
    >
      {/* Icon and Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        {icon}
        {isEditing ? (
          <>
            <TextField
              value={editableTitle}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              autoFocus
              variant='standard'
              sx={{
                fontFamily: 'Lato',
                fontSize: '1rem',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '0.15px',
                textAlign: 'left',
              }}
            />
            <Save
              height={18}
              width={18}
              color='#39585C'
              style={{
                cursor:
                  currentStage === 'customizeTemplate' ? 'pointer' : 'default',
              }}
              onClick={handleInputBlur}
            />
          </>
        ) : (
          <>
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '1rem',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '0.15px',
                textAlign: 'left',
              }}
            >
              {editableTitle}
            </Typography>

            <Pencil
              height={18}
              width={18}
              color='#39585C'
              onClick={handleTitleClick}
              style={{
                cursor:
                  currentStage === 'customizeTemplate' ? 'pointer' : 'default',
              }}
            />
          </>
        )}
      </Box>

      {/* Delete Button */}
      {currentStage === 'customizeTemplate' && (
        <Box
          sx={{
            border: '2px solid #D32F2F',
            borderRadius: '0.75rem',
            width: '3.3rem',
            height: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const updatedSections = Sections.map(section => {
              if (section.key === sectionKey) {
                section.status = false;
                section.map.status = false;
              }
              return section;
            });
            setSections(updatedSections);
          }}
        >
          <DeleteIcon height={25} width={25} />
        </Box>
      )}
    </Box>
  );
}

// function MapDisplay({ map }) {
//   return (
//     <Box
//       sx={{
//         width: '100%',
//         height: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <img
//         src={map}
//         alt=''
//         style={{
//           width: '100%',
//           height: 'auto',
//           objectFit: 'cover',
//         }}
//       />
//     </Box>
//   );
// }

function InfoLabel({ info }) {
  return (
    <Typography
      sx={{
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 800,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#9A9A9A',
        padding: '0.5rem',
        borderRadius: '0.75rem',
      }}
    >
      {info}
    </Typography>
  );
}

function AddBlockModal() {
  const {
    setAddBlockModal,
    Sections,
    setSections,
    addBlockModal,
    fetchLayerUrlsAndTitles,
    fetchToken,
    Maps,
    fetchFields,
    updateSections,
  } = useContext(ReportTemplatesContext);
  const [searchText, setSearchText] = useState('');
  const [localLoader, setLocalLoader] = useState(true);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null); // will be one of the layer names

  const handleAddBlock = useCallback(
    (block, sectionTitle) => {
      const updatedSections = Sections.map(section => {
        if (section.key === sectionTitle) {
          const activeBlocksCount = section.blocks.filter(
            secBlock => secBlock.status,
          ).length;
          if (activeBlocksCount >= 9) {
            alert('Cannot add more than 9 blocks in a section');
            return section;
          }
          section.blocks = section.blocks.map(secBlock => {
            if (secBlock.title === block.title) {
              return { ...secBlock, status: true };
            }

            return secBlock;
          });
        }
        return section;
      });

      setSections(updatedSections);
    },
    [Sections, setSections],
  );

  // Filter blocks by section and search text
  const blocksToShow = Sections.flatMap(section =>
    section.key === addBlockModal.callingSection
      ? section.blocks.filter(
          block =>
            !block.status &&
            block.title.toLowerCase().includes(searchText.toLowerCase()),
        )
      : [],
  );

  async function getGroupsForMapServer(mapserverURL) {
    try {
      console.log('Getting Groups for Map server...');
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/map/groups`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            // Use JSON.stringify here
            mapserverURL: mapserverURL,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Couldn't fetch the layers");
      }

      const data = await response.json();
      console.log({ data });

      if (!data.layers) {
        throw new Error('Layers data is missing from the response');
      }

      console.log({ layers: data.layers });

      return data.layers;
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to handle it in the calling function
    }
  }

  async function localGroupsFetcherRoutine() {
    try {
      const callingSectionTemp = Sections.find(
        section => section.key === addBlockModal.callingSection,
      );
      const token = await fetchToken();

      const layerUrlsAndTitles = await fetchLayerUrlsAndTitles(
        callingSectionTemp.map.webmapID,
        token,
      );

      if (!layerUrlsAndTitles) {
        console.log('layerUrlsAndTitles is empty');
        return;
      }

      console.log({ layerUrlsAndTitles });
      let mapServerURL = null;
      layerUrlsAndTitles.forEach(layer => {
        if (layer?.url.includes('/MapServer')) {
          const match = layer.url.match(/.*\/MapServer\//);
          if (match) {
            mapServerURL = match[0];
          }
        }
      });

      if (mapServerURL) {
        console.log(mapServerURL);
        const groupNamesAndIDs = await getGroupsForMapServer(mapServerURL);

        const groupUrlsAndNames = groupNamesAndIDs.map(group => {
          return {
            url: `${mapServerURL}${group.id}`,
            title: group.name,
          };
        });

        console.log({ groupUrlsAndNames });
        setGroups(groupUrlsAndNames);
      } else {
        const layers = layerUrlsAndTitles.map(layer => layer.url);
        const newBlocks = await fetchFields(layers, token);
        if (!newBlocks) {
          throw new Error('newBlocks is empty');
        }
        console.log({ newBlocks });
        updateSections(addBlockModal.callingSection, newBlocks);
        setSelectedGroup('None');
      }

      setLocalLoader(false);
    } catch (error) {
      console.error(error);
      setLocalLoader(false);
    }
  }

  async function localBlocksFetcherRoutine() {
    try {
      const token = await fetchToken();

      const layerUrls = [];
      layerUrls.push(selectedGroup.url);
      const newBlocks = await fetchFields(layerUrls, token);
      if (!newBlocks) {
        throw new Error('newBlocks is empty');
      }
      console.log({ newBlocks });
      updateSections(addBlockModal.callingSection, newBlocks);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (addBlockModal.status === true) localGroupsFetcherRoutine();
  }, []);

  useEffect(() => {
    if (!selectedGroup || selectedGroup === 'None') return;
    localBlocksFetcherRoutine();
  }, [selectedGroup]);

  return (
    <Box
      sx={{
        zIndex: 3,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Actual Modal */}
      <Box
        sx={{
          width: '40.5rem',
          height: '35.25rem',
          backgroundColor: '#FFFFFF',
          borderRadius: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.85rem',
        }}
      >
        {/* Heading + Close Button */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#F9F9F9',
            padding: '1.5rem',
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
          }}
        >
          {/* Heading */}
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              fontWeight: 700,
              color: '#004852',
            }}
          >
            Data Block Name
          </Typography>
          {/* Close Button */}
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setAddBlockModal({ status: false, callingSection: null });
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-x'
            >
              <path d='M18 6 6 18' />
              <path d='m6 6 12 12' />
            </svg>
          </Box>
        </Box>

        {selectedGroup === null ? (
          localLoader === true ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '5rem',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                overflowY: 'auto',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Lato',
                  fontSize: '1rem',
                  fontWeight: 700,
                  color: '#004852',
                  marginLeft: '1rem',
                }}
              >
                Pick a group to fetch its blocks
              </Typography>
              <Box>
                {groups.map(group => {
                  return (
                    <Box
                      key={group.title}
                      sx={{
                        paddingY: '0.5rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: '1rem',
                          fontFamily: 'Lato',
                          fontSize: '1rem',
                          fontWeight: 700,
                          width: '22rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {group.title}
                      </Typography>
                      <Button
                        sx={{
                          backgroundColor: '#4FA490',
                          height: '3.5rem',
                          width: '14rem', // Fixed width
                          color: '#FFFFFF',
                          padding: '0.5rem 1.25rem',
                          borderRadius: '0.75rem',
                          '&:hover': {
                            backgroundColor: '#4FA490',
                          },
                          marginRight: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                          console.log({ group });
                          setSelectedGroup(group);
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '1rem',
                            fontWeight: 700,
                          }}
                        >
                          Select Group
                        </Typography>
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )
        ) : (
          <>
            {/* Search Bar */}
            <Box sx={{ marginX: '1.5rem' }}>
              <input
                type='text'
                id='blockName'
                name='blockName'
                placeholder='Search for Data Blocks'
                style={{
                  boxSizing: 'border-box',
                  height: '3rem',
                  width: '100%',
                  padding: '1rem',
                  border: '1px solid #E2E8F0',
                  borderRadius: '0.75rem',
                }}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
            </Box>

            {/* Virtualized Block List */}
            {localLoader === true ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '5rem',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <FixedSizeList
                height={400}
                itemCount={blocksToShow.length}
                itemSize={70}
                width={'100%'}
                style={{
                  display: 'flex',
                }}
              >
                {({ index, style }) => {
                  const block = blocksToShow[index];
                  return (
                    <Box
                      key={block.title}
                      sx={{
                        ...style,
                        paddingY: '0.5rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        maxHeight: '4.5rem', // Ensure the height remains constant
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: '1rem',
                          fontFamily: 'Lato',
                          fontSize: '1rem',
                          fontWeight: 700,
                          width: '22rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal', // Allow text to wrap
                        }}
                      >
                        {block.title}
                      </Typography>
                      <Button
                        sx={{
                          backgroundColor: '#4FA490',
                          height: '3.5rem',
                          width: '14rem', // Fixed width
                          color: '#FFFFFF',
                          padding: '0.5rem 1.25rem',
                          borderRadius: '0.75rem',
                          '&:hover': {
                            backgroundColor: '#4FA490',
                          },
                          marginRight: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() =>
                          handleAddBlock(block, addBlockModal.callingSection)
                        }
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '1rem',
                            fontWeight: 700,
                          }}
                        >
                          Add Data Block
                        </Typography>
                      </Button>
                    </Box>
                  );
                }}
              </FixedSizeList>
            )}
          </>
        )}
        {/* Render this only if selected is not null */}
      </Box>
    </Box>
  );
}

function CustomSymbolizationModal({
  Section,
  setModal,
  setDefaultMapFlag,
  defaultMapFlag,
  setBlock,
}) {
  const {
    fetchLayerUrlsAndTitles,
    fetchToken,
    Maps,
    fetchFields,
    addCustomMapInfo,
  } = useContext(ReportTemplatesContext);

  const [modeSelected, setModeSelected] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [localLoader, setLocalLoader] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null); // will be one of the layer names
  const [localBlocks, setLocalBlocks] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [pendingBlock, setPendingBlock] = useState(null); // Stores the block pending confirmation

  const handleBlockSelection = useCallback(block => {
    setPendingBlock(block);
    setConfirmationDialogOpen(true); // Open the dialog when a block is selected
  }, []);

  const handleConfirmation = boundariesOnly => {
    setBlock({ ...pendingBlock, boundaries: boundariesOnly });
    addCustomMapInfo(Section.key, {
      ...pendingBlock,
      boundaries: boundariesOnly,
    });
    setModal(false);
    setConfirmationDialogOpen(false);
    setPendingBlock(null);
  };

  const blocksToShow = localBlocks.filter(
    block =>
      !block.status &&
      block.title.toLowerCase().includes(searchText.toLowerCase()),
  );

  async function getGroupsForMapServer(mapserverURL) {
    try {
      console.log('Getting Groups for Map server...');
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/map/groups`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            mapserverURL: mapserverURL,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Couldn't fetch the layers");
      }

      const data = await response.json();
      console.log({ data });

      if (!data.layers) {
        throw new Error('Layers data is missing from the response');
      }

      console.log({ layers: data.layers });

      return data.layers;
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to handle it in the calling function
    }
  }

  async function localGroupsFetcherRoutine() {
    try {
      setLocalLoader(true);
      const token = await fetchToken();

      const layerUrlsAndTitles = await fetchLayerUrlsAndTitles(
        Section.map.webmapID,
        token,
      );

      if (!layerUrlsAndTitles) {
        console.log('layerUrlsAndTitles is empty');
        return;
      }

      console.log({ layerUrlsAndTitles });
      let mapServerURL = null;
      layerUrlsAndTitles.forEach(layer => {
        if (layer?.url.includes('/MapServer')) {
          const match = layer.url.match(/.*\/MapServer\//);
          if (match) {
            mapServerURL = match[0];
          }
        }
      });

      if (mapServerURL) {
        console.log(mapServerURL);
        const groupNamesAndIDs = await getGroupsForMapServer(mapServerURL);

        const groupUrlsAndNames = groupNamesAndIDs.map(group => {
          return {
            url: `${mapServerURL}${group.id}`,
            title: group.name,
          };
        });

        console.log({ groupUrlsAndNames });
        setGroups(groupUrlsAndNames);
      } else {
        const layers = layerUrlsAndTitles.map(layer => layer.url);
        const newBlocks = await fetchFields(layers, token);
        if (!newBlocks) {
          throw new Error('newBlocks is empty');
        }
        console.log({ newBlocks });
        setLocalBlocks(newBlocks);
        setSelectedGroup('None');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLocalLoader(false);
    }
  }

  async function localBlocksFetcherRoutine() {
    try {
      setLocalLoader(true);
      const token = await fetchToken();

      const layerUrls = [];
      layerUrls.push(selectedGroup.url);
      const newBlocks = await fetchFields(layerUrls, token);
      if (!newBlocks) {
        throw new Error('newBlocks is empty');
      }
      console.log({ newBlocks });
      setLocalBlocks(newBlocks);
    } catch (error) {
      console.error(error);
    } finally {
      setLocalLoader(false);
    }
  }

  useEffect(() => {
    if (!modeSelected) return;
    localGroupsFetcherRoutine();
  }, [modeSelected]);

  useEffect(() => {
    if (!selectedGroup || selectedGroup === 'None') return;
    localBlocksFetcherRoutine();
  }, [selectedGroup]);

  return (
    <Box
      sx={{
        zIndex: 3,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Actual Modal */}
      <Box
        sx={{
          width: '40.5rem',
          height: '35.25rem',
          backgroundColor: '#FFFFFF',
          borderRadius: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.85rem',
        }}
      >
        {/* Heading + Close Button */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#F9F9F9',
            padding: '1.5rem',
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
          }}
        >
          {/* Heading */}
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '1rem',
              fontWeight: 700,
              color: '#004852',
            }}
          >
            Custom Symbolization
          </Typography>
          {/* Close Button */}
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setModal(false);
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-x'
            >
              <path d='M18 6 6 18' />
              <path d='m6 6 12 12' />
            </svg>
          </Box>
        </Box>

        {!modeSelected && (
          <Box>
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '1rem',
                fontWeight: 700,
                color: '#004852',
                marginLeft: '1rem',
              }}
            >
              Select Map Mode
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '5rem',
                gap: '2rem',
                alignItems: 'left',
              }}
            >
              <Box
                sx={{
                  width: '10rem',
                  marginLeft: '1rem',
                  paddingX: '2rem',
                  paddingY: '1rem',
                  border: '2px solid #004852',
                  borderRadius: '0.75rem',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setDefaultMapFlag();
                  setModeSelected(true);
                  setModal(false);
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: '1rem',
                    fontWeight: 700,
                    color: '#004852',
                  }}
                >
                  Default Map
                </Typography>
              </Box>

              <Box
                sx={{
                  width: '10rem',
                  marginLeft: '1rem',
                  paddingX: '2rem',
                  paddingY: '1rem',
                  border: '2px solid #004852',
                  borderRadius: '0.75rem',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setModeSelected(true);
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: '1rem',
                    fontWeight: 700,
                    color: '#004852',
                  }}
                >
                  Custom
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {modeSelected &&
          (selectedGroup === null ? (
            localLoader === true ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '5rem',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  overflowY: 'auto',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: '1rem',
                    fontWeight: 700,
                    color: '#004852',
                    marginLeft: '1rem',
                  }}
                >
                  Pick a group to fetch its blocks
                </Typography>
                <Box>
                  {groups.map(group => {
                    return (
                      <Box
                        key={group.title}
                        sx={{
                          paddingY: '0.5rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            marginLeft: '1rem',
                            fontFamily: 'Lato',
                            fontSize: '1rem',
                            fontWeight: 700,
                            width: '22rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {group.title}
                        </Typography>
                        <Button
                          sx={{
                            backgroundColor: '#4FA490',
                            height: '3.5rem',
                            width: '14rem', // Fixed width
                            color: '#FFFFFF',
                            padding: '0.5rem 1.25rem',
                            borderRadius: '0.75rem',
                            '&:hover': {
                              backgroundColor: '#4FA490',
                            },
                            marginRight: '1rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() => {
                            console.log({ group });
                            setSelectedGroup(group);
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Lato',
                              fontSize: '1rem',
                              fontWeight: 700,
                            }}
                          >
                            Select Group
                          </Typography>
                        </Button>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )
          ) : (
            <>
              {/* Search Bar */}
              <Box sx={{ marginX: '1.5rem' }}>
                <input
                  type='text'
                  id='blockName'
                  name='blockName'
                  placeholder='Search for Data Blocks'
                  style={{
                    boxSizing: 'border-box',
                    height: '3rem',
                    width: '100%',
                    padding: '1rem',
                    border: '1px solid #E2E8F0',
                    borderRadius: '0.75rem',
                  }}
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </Box>

              {/* Virtualized Block List */}
              {localLoader === true ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5rem',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <FixedSizeList
                  height={400}
                  itemCount={blocksToShow.length}
                  itemSize={70}
                  width={'100%'}
                  style={{
                    display: 'flex',
                  }}
                >
                  {({ index, style }) => {
                    const block = blocksToShow[index];
                    return (
                      <Box
                        key={block.title}
                        sx={{
                          ...style,
                          paddingY: '0.5rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            marginLeft: '1rem',
                            fontFamily: 'Lato',
                            fontSize: '1rem',
                            fontWeight: 700,
                            width: '22rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {block.title}
                        </Typography>
                        <Button
                          sx={{
                            backgroundColor: '#4FA490',
                            height: '3.5rem',
                            width: '14rem', // Fixed width
                            color: '#FFFFFF',
                            padding: '0.5rem 1.25rem',
                            borderRadius: '0.75rem',
                            '&:hover': {
                              backgroundColor: '#4FA490',
                            },
                            marginRight: '1rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() => handleBlockSelection(block)}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Lato',
                              fontSize: '1rem',
                              fontWeight: 700,
                            }}
                          >
                            Map Data Block
                          </Typography>
                        </Button>
                      </Box>
                    );
                  }}
                </FixedSizeList>
              )}
            </>
          ))}

        {/* Confirmation Dialog */}
        <Dialog
          open={confirmationDialogOpen}
          onClose={() => setConfirmationDialogOpen(false)}
        >
          <DialogTitle>Select Display Mode</DialogTitle>
          <DialogActions>
            <Button onClick={() => handleConfirmation(true)}>
              Boundaries Only
            </Button>
            <Button onClick={() => handleConfirmation(false)}>
              Full Map Data
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

function NearestGroupsBlock() {
  const { removeBlock } = useContext(ReportTemplatesContext);
  const style = {
    colorBox: {
      height: '1rem',
      width: '1rem',
    },
    groupBox: {
      display: 'flex',
      gap: '1.25rem',
      alignItems: 'center',
    },
    groupTitle: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '3.5rem',
        borderRadius: '1rem',
        display: 'flex',

        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          paddingX: '1rem',
          paddingY: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <BlockNameHeading title='Nearest Groups' />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <Box sx={style.groupBox}>
            <Box
              sx={[
                style.colorBox,
                {
                  backgroundColor: '#9494E1',
                },
              ]}
            />
            <Typography sx={style.groupTitle}>
              Bright Young Professionals
            </Typography>
          </Box>
          <Box sx={style.groupBox}>
            <Box
              sx={[
                style.colorBox,
                {
                  backgroundColor: '#FFE773',
                },
              ]}
            />
            <Typography sx={style.groupTitle}>Workday Drive</Typography>
          </Box>
          <Box sx={style.groupBox}>
            <Box
              sx={[
                style.colorBox,
                {
                  backgroundColor: '#B884B7',
                },
              ]}
            />
            <Typography sx={style.groupTitle}>Savvy Suburbanites</Typography>
          </Box>
          <Box sx={style.groupBox}>
            <Box
              sx={[
                style.colorBox,
                {
                  backgroundColor: '#FFE773',
                },
              ]}
            />
            <Typography sx={style.groupTitle}>Urban Edge Families</Typography>
          </Box>
          <Box sx={style.groupBox}>
            <Box
              sx={[
                style.colorBox,
                {
                  backgroundColor: '#72C5B5',
                },
              ]}
            />
            <Typography sx={style.groupTitle}>
              Comfortable Empty Nesters
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          removeBlock('Nearest Groups', 'demographics');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function GenericBlock({ title, sectionKey }) {
  const { removeBlock } = useContext(ReportTemplatesContext);
  const styles = {
    text: {
      fontFamily: 'Lato',
      lineHeight: '20px',
      textAlign: 'center',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Text */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.75rem',
        }}
      >
        <Typography
          sx={[
            styles.text,
            {
              fontSize: '1.1rem',
              fontWeight: 500,
            },
          ]}
        >
          {title}
        </Typography>

        <Typography
          sx={[
            styles.text,
            {
              fontSize: '1.4rem',
              fontWeight: 900,
            },
          ]}
        >
          {{
            crime: '0.45x',
            expansion: '-0.96%',
            income: '$173,568',
            annual_income: '-0.04 %',
            rent: '$4,544',
            msa: '60',
          }[sectionKey] || null}
          {{
            'Price Appreciation Since 2011': '2.90x',
            'Background Rate of Appreciation (1990 - present)':
              '12.27% per year',
          }[title] || null}
        </Typography>
        {sectionKey !== 'income' ||
          (sectionKey !== 'rent' && (
            <Typography
              sx={[
                styles.text,
                {
                  fontSize: '1rem',
                  fontWeight: 500,
                },
              ]}
            >
              {
                {
                  crime: 'National Average',
                  expansion: 'Declining',
                }[sectionKey]
              }
            </Typography>
          ))}
      </Box>
      {/* SVGs */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.75rem',
        }}
      >
        {sectionKey !== 'hpi' &&
          sectionKey !== 'msa' &&
          sectionKey !== 'income' &&
          sectionKey !== 'annual_income' && (
            <Box
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                removeBlock(title, sectionKey);
              }}
            >
              <RemoveBlockSVG />
            </Box>
          )}
      </Box>
    </Box>
  );
}

function BlockNameHeading({ title }) {
  return (
    <Typography
      sx={{
        fontFamily: 'Lato',
        fontSize: '1.4rem',
        fontWeight: 900,
        lineHeight: '20px',
        textAlign: 'center',
        color: '#39585C',
      }}
    >
      {title}
    </Typography>
  );
}

function GradeBlock() {
  const { removeBlock } = useContext(ReportTemplatesContext);

  const commonTypographyStyles = {
    fontFamily: 'Lato',
    lineHeight: '20px',
    textAlign: 'center',
  };
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '0.75rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{
          ...commonTypographyStyles,
          fontSize: '1.1rem',
          fontWeight: 500,
          color: '#39585C',
        }}
      >
        Grade
      </Typography>
      <Typography
        sx={{
          ...commonTypographyStyles,
          fontSize: '22.4px',
          fontWeight: 700,
        }}
      >
        7C
      </Typography>

      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          removeBlock('Grade', 'demographics');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function DemographicGroupBlock() {
  const { removeBlock } = useContext(ReportTemplatesContext);
  const style = {
    colorBox: {
      height: '1rem',
      width: '1rem',
    },
    groupTitle: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1rem',
        paddingX: '1.75rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '0.75rem',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '0.75rem',
        }}
      >
        <BlockNameHeading title='Demographic Group' />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '2rem',
        }}
      >
        <Box
          sx={[
            style.colorBox,
            {
              backgroundColor: '#FFE773',
            },
          ]}
        />
        <Typography sx={style.groupTitle}>Urban Edge Families</Typography>
      </Box>

      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock('Demographic Group', 'demographics');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function WhoWeAreBlock() {
  const { removeBlock } = useContext(ReportTemplatesContext);
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1rem',
        paddingX: '1.75rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '0.75rem',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '.5rem',
        }}
      >
        <BlockNameHeading title={'Who We Are'} />
      </Box>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Lato',
            fontSize: '1rem',
            fontWeight: '400',
            textAlign: 'justify',
          }}
        >
          On their own for the first time, Dorms to Diplomas residents are just
          learning about finance and cooking. Frozen dinners and fast food are
          common options. Shopping trips are sporadic, and preferences for
          products are still being established. Many carry a balance on their
          credit card so they can buy what they want now.
        </Typography>
      </Box>
      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock('Who We Are', 'demographics');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function NeighborhoodMapLegend() {
  const styles = {
    columnStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingY: '1rem',
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.25rem',
    },
    innerRow: { display: 'flex', alignItems: 'center', gap: '1rem' },
  };

  function ColoredShape({ color, shape }) {
    const size = '1rem';
    const styles = {
      square: {
        width: size,
        height: size,
        backgroundColor: color,
      },
      diamond: {
        width: size,
        height: size,
        backgroundColor: color,
        transform: 'rotate(45deg)',
      },
      circle: {
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: color,
      },
    };

    return <Box sx={styles[shape]} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F9F9F9',
        borderRadius: '1rem',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '1rem',
      }}
    >
      <Box sx={styles.columnStyle}>
        <Box sx={styles.innerContainer}>
          <Box sx={styles.innerRow}>
            <ColoredShape color={'red'} shape={'circle'} />
            <Typography>Dollar General</Typography>
          </Box>
          <Box sx={styles.innerRow}>
            <ColoredShape color={'red'} shape={'square'} />
            <Typography>Dollar Tree</Typography>
          </Box>
          <Box sx={styles.innerRow}>
            <ColoredShape color={'maroon'} shape={'diamond'} />
            <Typography>Family Dollar</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.columnStyle}>
        <Box sx={styles.innerContainer}>
          <Box sx={styles.innerRow}>
            <ColoredShape color={'orange'} shape={'circle'} />
            <Typography>Walmart</Typography>
          </Box>
          <Box sx={styles.innerRow}>
            <ColoredShape color={'yellow'} shape={'circle'} />
            <Typography>Target</Typography>
          </Box>
          <Box sx={styles.innerRow}>
            <ColoredShape color={'green'} shape={'square'} />
            <Typography>Home Depot</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.columnStyle}>
        <Box sx={styles.innerContainer}>
          <Box sx={styles.innerRow}>
            <ColoredShape color={'green'} shape={'circle'} />
            <Typography>Star Bucks</Typography>
          </Box>
          <Box sx={styles.innerRow}>
            <ColoredShape color={'lime'} shape={'diamond'} />
            <Typography>Whole Foods</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function NearestStoresBlock() {
  const { removeBlock } = useContext(ReportTemplatesContext);
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '3.5rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '.75rem',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '.5rem',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Lato',
            fontSize: '1.4rem',
            fontWeight: 900,
            lineHeight: '20px',
            textAlign: 'center',
            color: '#39585C',
          }}
        >
          Nearest Stores
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '.5rem',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Lato',
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: '20px',
          }}
        >
          Walmart
        </Typography>
      </Box>

      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock('Nearest Stores', 'neighborhood');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function HousePriceIndexMapLegned() {
  const styles = {
    numbers: {
      width: '100%',
      margin: '0.5rem 0',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    gradient: {
      width: '100%',
      height: '2rem',
      background:
        'linear-gradient(90deg, hsla(75, 78%, 29%, 1) 0%, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 0%, 1) 100%)',
    },
  };
  return (
    <Box>
      <Box>
        <BlockNameHeading title={'Price Appreciation Since 2011 (%)'} />
        <Box>
          <Box sx={styles.numbers}>
            <Box>&gt;50</Box>
            <Box>42.5</Box>
            <Box>35</Box>
            <Box>27.5</Box>
            <Box>&lt;20</Box>
          </Box>
          <Box sx={styles.gradient}></Box>
        </Box>
      </Box>
    </Box>
  );
}

function GenericAppreciationBlock({ title }) {
  const { removeBlock } = useContext(ReportTemplatesContext);
  return (
    <Box
      sx={{
        flex: '1 0 100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '.75rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '.5rem',
        }}
      >
        <GenericBlock title={title} sectionKey={'hpi'} />
        <GenericBlock title={title} sectionKey={'hpi'} />
        <GenericBlock title={title} sectionKey={'hpi'} />
      </Box>
      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock(title, 'hpi');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function HousePriceIndexHeader() {
  const styles = {
    typography: {
      flex: '1',
      color: 'rgb(57, 88, 92)',
      fontSize: '1.5rem',
      fontFamily: 'Lato',
      lineHeight: '1.25rem',
      textAlign: 'center',
      fontWeight: '900',
    },
  };
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '2rem',
      }}
    >
      <Typography sx={styles.typography}>TRACT</Typography>
      <Typography sx={styles.typography}>ZIP</Typography>
      <Typography sx={styles.typography}>COUNTY</Typography>
    </Box>
  );
}

function HousePriceIndexGraphBlock() {
  const { removeBlock } = useContext(ReportTemplatesContext);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        gap: '.5rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={hpiChart}
        alt='HPI Chart'
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />

      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock('HPI TRACT/ZIP INDEX', 'hpi');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function SchoolDistrictNameBlock() {
  const { removeBlock } = useContext(ReportTemplatesContext);
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '3.5rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.75rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '.5rem',
          alignItems: 'center',
        }}
      >
        <BlockNameHeading title='School District Name' />
      </Box>
      <Typography
        sx={{
          fontFamily: 'Lato',
          fontSize: '1.25rem',
          fontWeight: 500,
          lineHeight: '20px',
          textAlign: 'center',
        }}
      >
        Hillsborough
      </Typography>
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          removeBlock('School District Name', 'schools');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function NearestThreeSchoolsBlock() {
  const { removeBlock } = useContext(ReportTemplatesContext);
  const style = {
    colorBox: {
      height: '1rem',
      width: '1rem',
    },
    schoolBox: {
      display: 'flex',
      gap: '1.25rem',
      alignItems: 'center',
    },
    schoolTitle: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
  };
  const schools = [
    { name: 'School One', rating: '8/10' },
    { name: 'School Two', rating: '7/10' },
    { name: 'School Three', rating: '9/10' },
  ];

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '3.5rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          paddingX: '1rem',
          paddingY: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '.5rem',
            alignItems: 'center',
          }}
        >
          <BlockNameHeading title='Nearest Three Schools' />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <Box sx={style.schoolBox}>
            <Box
              sx={[
                style.colorBox,
                {
                  backgroundColor: '#FF5733',
                },
              ]}
            />
            <Typography sx={style.schoolTitle}>School One</Typography>
          </Box>
          <Box sx={style.schoolBox}>
            <Box
              sx={[
                style.colorBox,
                {
                  backgroundColor: '#33FF57',
                },
              ]}
            />
            <Typography sx={style.schoolTitle}>School Two</Typography>
          </Box>
          <Box sx={style.schoolBox}>
            <Box
              sx={[
                style.colorBox,
                {
                  backgroundColor: '#3357FF',
                },
              ]}
            />
            <Typography sx={style.schoolTitle}>School Three</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          removeBlock('Nearest 3 Schools', 'schools');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function GenericIncomeBlock({ title, sectionKey, blocks }) {
  const { removeBlock } = useContext(ReportTemplatesContext);
  const styles = {
    rowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '1rem',
      width: '100%',
    },
  };

  return (
    <Box
      sx={{
        flex: '1 0 100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
      }}
    >
      <BlockNameHeading title={title} />
      {blocks.map((blockRow, rowIndex) => (
        <Box key={rowIndex} sx={styles.rowContainer}>
          {blockRow.map((block, blockIndex) => (
            <GenericBlock
              key={blockIndex}
              title={block}
              sectionKey={sectionKey}
            />
          ))}
        </Box>
      ))}
      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock(title, 'income');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function StatsMSABlock() {
  const { removeBlock } = useContext(ReportTemplatesContext);
  const styles = {
    rowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '1rem',
      width: '100%',
    },
  };

  return (
    <Box
      sx={{
        flex: '1 0 100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Lato',
          fontSize: '1.2rem',
          fontWeight: '600',
        }}
      >
        San Francisco-Oakland-Berkeley, CA
      </Typography>
      <Box sx={styles.rowContainer}>
        <GenericBlock title='Housing Affordability Index' sectionKey='msa' />
        <GenericBlock title='Wealth Index' sectionKey='msa' />
        <GenericBlock title='Population Growth Rate' sectionKey='msa' />
        <GenericBlock title='Total Population' sectionKey='msa' />
      </Box>
      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock('Stat Block', 'msa');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function DiversificationBlock({ title }) {
  const { removeBlock } = useContext(ReportTemplatesContext);

  const styles = {
    boxStyle: {
      borderBottom: '2px solid gray',

      display: 'flex',
      gap: '1rem',
      padding: '0.5rem',
    },
    typographyStyle: {
      fontFamily: 'Lato',
      fontSize: '1.2rem',
      fontWeight: '600',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        alignItems: 'center',
        padding: '1.5rem',
      }}
    >
      <Box sx={styles.boxStyle}>
        <Typography
          sx={{
            ...styles.typographyStyle,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={styles.boxStyle}>
        <Typography
          sx={{
            ...styles.typographyStyle,
            fontWeight: '900',
          }}
        >
          0.66
        </Typography>
      </Box>
      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock(title, 'msa');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

function Top5Block({ title }) {
  const { removeBlock } = useContext(ReportTemplatesContext);

  const data = {
    'Top 5 Businesses by Sector': [
      {
        title: '2023 Total Businesses (NAICS)',
        value: '219,230',
      },
      {
        title: '2023 Retail Trade Businesses (NAICS)',
        value: '219,230',
      },
      {
        title: '2023 Other Service excl Pub Admin Bus (NAICS)',
        value: '219,230',
      },
      {
        title: '2023 Prof/Scientific/Tech Service Bus (NAICS)',
        value: '219,230',
      },
      {
        title: '2023 HealthCare/Social Assist Bus (NAICS)',
        value: '219,230',
      },
    ],
    'Top 5 Employers by Sector': [
      {
        title: '2023 Total Employees (NAICS)',
        value: '2,239,078',
      },
      {
        title: '2023 HealthCare/Social Assist Emp (NAICS)',
        value: '2,239,078',
      },
      {
        title: '2023 Retail Trade Employees (NAICS)',
        value: '2,239,078',
      },
      {
        title: '2023 Prof/Scientific/Tech Service Emp (NAICS)',
        value: '2,239,078',
      },
      {
        title: '2023 Accommodation/Food Service Emp (NAICS)',
        value: '2,239,078',
      },
    ],
  };

  const styles = {
    typography: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: '400',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        alignItems: 'center',
        padding: '1.5rem',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#F9F9F9',
          display: 'flex',
          paddingX: '1.5rem',
          paddingY: '.5rem',
          borderRadius: '1.2rem',
        }}
      >
        <BlockNameHeading title={title} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '.5rem',
        }}
      >
        {data[title].map((dict, index) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '.5rem',
                paddingY: '.25rem',
                borderBottom: '2px solid gray',
              }}
            >
              <Typography sx={styles.typography}>{dict.title}</Typography>
              <Typography
                sx={{
                  ...styles.typography,
                  fontWeight: '900',
                  fontSize: '1.25rem',
                }}
              >
                {dict.value}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock(title, 'msa');
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}
const DefaultBlock = ({ sectionKey, block }) => {
  const {
    removeBlock,
    updateBlockTitle,
    updatedBlockPrefixAndSuffix,
    updateBlockValue,
  } = useContext(ReportTemplatesContext);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState(block.title);
  const [valueLoader, setValueLoader] = useState(true);
  const [blockValue, setBlockValue] = useState(null);
  const [prefix, setPrefix] = useState(block.prefix || 'None');
  const [suffix, setSuffix] = useState(block.suffix || 'None');
  const [customPrefix, setCustomPrefix] = useState(
    block.prefix && !['$', '€', '£', 'None'].includes(block.prefix)
      ? block.prefix
      : '',
  );
  const [customSuffix, setCustomSuffix] = useState(
    block.suffix && !['%', 'x', 'None'].includes(block.suffix)
      ? block.suffix
      : '',
  );

  const prefixOptions =
    suffix === '%' ? ['Custom', 'None'] : ['$', '€', '£', 'Custom', 'None'];
  const suffixOptions = ['$', '€', '£'].includes(prefix)
    ? ['Custom', 'None']
    : ['%', 'x', 'Custom', 'None'];

  const formatNumber = value => {
    if (isNaN(value)) return value; // Return if not a number
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handleSave = () => {
    updateBlockTitle(block.title, title, sectionKey);
    setEdit(false);
  };

  useEffect(() => {
    async function getBlockValue() {
      try {
        const url = process.env.REACT_APP_NODE_URL + '/templates/block-value';
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ block }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setBlockValue(data.value);
        updateBlockValue(block.title, sectionKey, data.value, block?.group);
        setValueLoader(false);
      } catch (error) {
        console.error(error);
        setValueLoader(false);
      }
    }

    if (block) {
      if (!block?.templateValue) {
        getBlockValue();
      } else {
        setBlockValue(block.templateValue);
        setValueLoader(false);
      }
    }
  }, [block]);

  useEffect(() => {
    const appliedPrefix = prefix === 'Custom' ? customPrefix : prefix;
    const appliedSuffix = suffix === 'Custom' ? customSuffix : suffix;

    updatedBlockPrefixAndSuffix(
      block.title,
      sectionKey,
      appliedPrefix,
      appliedSuffix,
    );
  }, [prefix, suffix, customPrefix, customSuffix]);

  const [activeDrags, setActiveDrags] = useState(0);
  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 });
  const [controlledPosition, setControlledPosition] = useState({
    x: -400,
    y: 200,
  });

  const onStart = () => {
    setActiveDrags(prev => prev + 1);
  };

  const onStop = () => {
    setActiveDrags(prev => prev - 1);
  };

  const dragHandlers = { onStart, onStop };

  return (
    <Draggable grid={[25, 25]} handle='svg' {...dragHandlers}>
      <Box
        sx={{
          flex: 1,
          backgroundColor: '#F9F9F9',
          paddingY: '1.25rem',
          paddingX: '1rem',
          borderRadius: '1rem',
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <GripVertical
          height={28}
          width={28}
          style={{
            color: 'gray',
            cursor: 'pointer',
          }}
        />

        {block?.group && (
          <Typography
            sx={{
              fontFamily: 'Lato',
              lineHeight: '1.5rem',
              textAlign: 'center',
              fontSize: '1.1rem',
              fontWeight: 700,
              color: '#39585C',
            }}
          >
            {block.group}
          </Typography>
        )}
        <Box sx={{ display: 'flex', gap: '.65rem', alignItems: 'center' }}>
          {edit ? (
            <TextField
              value={title}
              onChange={e => setTitle(e.target.value)}
              size='small'
              sx={{ width: '150px' }}
            />
          ) : (
            <Typography
              sx={{
                fontFamily: 'Lato',
                lineHeight: '1.5rem',
                textAlign: 'center',
                fontSize: '1.1rem',
                fontWeight: 500,
                color: '#39585C',
              }}
            >
              {block.title}
            </Typography>
          )}
          <IconButton onClick={() => (edit ? handleSave() : setEdit(true))}>
            {edit ? (
              <Save height={18} width={18} color='#39585C' />
            ) : (
              <Pencil height={18} width={18} color='#39585C' />
            )}
          </IconButton>
        </Box>

        {valueLoader ? (
          <CircularProgress />
        ) : (
          <Typography
            sx={{
              fontFamily: 'Lato',
              lineHeight: '1.5rem',
              textAlign: 'center',
              fontSize: '1.4rem',
              fontWeight: 900,
            }}
          >
            {prefix === 'Custom'
              ? customPrefix
              : prefix === 'None'
                ? null
                : prefix}
            {blockValue !== '' || blockValue !== null
              ? formatNumber(blockValue)
              : 'value'}
            {suffix === 'Custom'
              ? customSuffix
              : suffix === 'None'
                ? null
                : suffix}
          </Typography>
        )}

        <Typography
          sx={{
            fontFamily: 'Lato',
            lineHeight: '1.5rem',
            textAlign: 'center',
            fontSize: '1rem',
            fontWeight: 500,
          }}
        >
          National Average
        </Typography>

        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          {/* Prefix Dropdown */}
          <TextField
            select
            label='Prefix'
            value={prefix}
            onChange={e => setPrefix(e.target.value)}
            sx={{ minWidth: '100px' }}
          >
            {prefixOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          {prefix === 'Custom' && (
            <TextField
              label='Custom Prefix'
              value={customPrefix}
              onChange={e => {
                setCustomPrefix(e.target.value);
              }}
              size='small'
              sx={{ minWidth: '100px' }}
            />
          )}

          {/* Suffix Dropdown */}
          <TextField
            select
            label='Suffix'
            value={suffix}
            onChange={e => setSuffix(e.target.value)}
            sx={{ minWidth: '100px' }}
          >
            {suffixOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          {suffix === 'Custom' && (
            <TextField
              label='Custom Suffix'
              value={customSuffix}
              onChange={e => {
                setCustomSuffix(e.target.value);
              }}
              size='small'
              sx={{ minWidth: '100px' }}
            />
          )}
        </Box>

        <Box
          sx={{
            cursor: 'pointer',
            alignSelf: 'center',
          }}
          onClick={() => {
            removeBlock(block.title, sectionKey, block?.group);
          }}
        >
          <RemoveBlockSVG />
        </Box>
      </Box>
    </Draggable>
  );
};

function LiveMap({
  webmapID,
  token,
  selectedBlock,
  defaultSettings = true,
  outlines = false,
}) {
  const mapRef = useRef(null);

  useEffect(() => {
    let view;
    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/FeatureLayer',
      'esri/widgets/Legend',
      'esri/config',
      'esri/smartMapping/statistics/classBreaks',
      'esri/WebMap',
      'esri/geometry/Extent',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
    ]).then(
      ([
        Map,
        MapView,
        FeatureLayer,
        Legend,
        esriConfig,
        classBreaks,
        WebMap,
        Extent,
        Graphic,
        GraphicsLayer,
      ]) => {
        if (!webmapID || !token) return;
        esriConfig.apiKey = token;

        let webmap = null;
        const extent = new Extent({
          xmin: -94.590938015952,
          ymin: 39.062328001212,
          xmax: -94.588938015952,
          ymax: 39.064328001212,
          spatialReference: {
            wkid: 4326,
          },
        });
        if (defaultSettings) {
          webmap = new WebMap({
            portalItem: {
              id: webmapID,
            },
          });

          view = new MapView({
            container: mapRef.current,
            map: webmap,
            extent: extent,
            zoom: 14,
            navigation: {
              mouseWheelZoomEnabled: false,
            },
          });

          view.ui.add(
            new Legend({
              view: view,
            }),
            'top-right',
          );

          const customSymbol = {
            type: 'picture-marker',
            url: marker,
            width: '50px',
            height: '100px',
          };
          const center = {
            longitude: (extent.xmin + extent.xmax) / 2,
            latitude: (extent.ymin + extent.ymax) / 2,
          };

          view.graphics.add(
            new Graphic({
              geometry: {
                type: 'point',
                longitude: center.longitude,
                latitude: center.latitude,
              },
              symbol: customSymbol,
            }),
          );
        } else {
          const defaultSym = {
            type: 'simple-fill',

            outline: {
              color: selectedBlock?.boundaries
                ? [57, 88, 92, 1]
                : [255, 255, 255, 0],

              width: '1px',
            },
          };

          const renderer = {
            type: 'simple',
            symbol: defaultSym,
            label: `U.S. ${selectedBlock.group}`,
            visualVariables: [
              {
                type: 'color',
                field: selectedBlock.outfields[0],
                // normalizationField: "TOTPOP_CY",
                legendOptions: {
                  // title: "% population in poverty by county", // do smth abt the title
                },
              },
            ],
          };

          const customLayer = new FeatureLayer({
            url: selectedBlock.serviceURL,
            renderer: renderer,
            title: selectedBlock.title + ` (${selectedBlock.group})`,
            popupTemplate: {
              title: selectedBlock.title + ` (${selectedBlock.group})`,
              content: `{${selectedBlock.outfields[0]}}`, // work around these relative fields
              fieldInfos: [
                {
                  fieldName: selectedBlock.outfields[0],
                  format: {
                    digitSeparator: true,
                    places: 0,
                  },
                },
              ],
            },
          });

          customLayer.opacity = 0.6;
          customLayer.minScale = 0;
          customLayer.maxScale = 0;

          classBreaks({
            layer: customLayer,
            field: selectedBlock.outfields[0],
            // normalizationField: "TOTPOP_CY",
            classificationMethod: 'quantile',
            // numClasses: 5,
          }).then(function (response) {
            let breakInfos = response.classBreakInfos;
            console.log({ breakInfos });

            // Create dynamic stops for the visual variable using the breakInfos
            const dynamicStops = breakInfos.map((info, index) => {
              return {
                value: info.maxValue, // Use maxValue from breakInfo
                color:
                  index === 0
                    ? '#a8e9c7' // Map your colors here
                    : index === 1
                      ? '#d9f0ae'
                      : index === 2
                        ? '#f9d58d'
                        : index === 3
                          ? '#f1a178'
                          : '#e66565', // You can expand the colors
                label: info.label, // Use the label from breakInfo
              };
            });

            // Update the renderer with dynamic stops
            customLayer.renderer = {
              type: 'simple',
              symbol: defaultSym,
              visualVariables: [
                {
                  type: 'color',
                  field: selectedBlock.outfields[0],
                  // normalizationField: "TOTPOP_CY",
                  legendOptions: {
                    // title: "% population in poverty by county",
                  },
                  stops: dynamicStops, // Use the dynamically generated stops
                },
              ],
            };

            const map = new Map({
              basemap: 'gray-vector',
              layers: [customLayer],
            });

            console.log(customLayer.minScale);
            console.log(customLayer.maxScale);
            view = new MapView({
              container: mapRef.current,
              map: map,
              extent: extent,
              navigation: {
                mouseWheelZoomEnabled: false,
              },
              scale:
                customLayer.minScale === 0
                  ? customLayer.maxScale
                  : customLayer.minScale,
            });

            view.ui.add(
              new Legend({
                view: view,
              }),
              'top-right',
            );

            const customSymbol = {
              type: 'picture-marker',
              url: marker,
              width: '50px',
              height: '100px',
            };
            const center = {
              longitude: (extent.xmin + extent.xmax) / 2,
              latitude: (extent.ymin + extent.ymax) / 2,
            };

            view.graphics.add(
              new Graphic({
                geometry: {
                  type: 'point',
                  longitude: center.longitude,
                  latitude: center.latitude,
                },
                symbol: customSymbol,
              }),
            );
          });
        }
      },
    );

    return () => {
      if (view) {
        view.destroy();
      }
    };
  }, [webmapID, token, selectedBlock, defaultSettings]);

  return <div ref={mapRef} style={{ height: '30rem', width: '100%' }}></div>;
}

function NationalAverageCrimeIndex({ title, sectionKey }) {
  const { removeBlock } = useContext(ReportTemplatesContext);
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Lato',
          lineHeight: '1.5rem',
          textAlign: 'center',
          fontSize: '1.4rem',
          fontWeight: 900,
        }}
      >
        2.25x
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Lato',
          lineHeight: '1.5rem',
          textAlign: 'center',
          fontSize: '1rem',
          fontWeight: 500,
        }}
      >
        National Average
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Lato',
          lineHeight: '1.5rem',
          textAlign: 'center',
          fontSize: '1.1rem',
          fontWeight: 500,
          color: '#39585C',
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => {
          removeBlock(title, sectionKey);
        }}
      >
        <RemoveBlockSVG />
      </Box>
    </Box>
  );
}

export default ReportTemplates;
