import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import VestmapContext from '../../contexts/VestmapContext';

const titles = [
  'Income',
  'Demographics',
  'House Price Index',
  'Transitional Neighborhood Index (TNI)', // map to Rent
  'Schools',
  'Population Growth', // map to expansion
  'Crime Index:',
  'Expansion',
  'Rent',
  'Neighborhood',
  'Metropolitan Statistical Area:',
  'Abstract',

  // above but with colon
  'Income:',
  'Demographics:',
  'House Price Index:',
  'Transitional Neighborhood Index (TNI):', // map to Rent
  'Schools:',
  'Population Growth:', // map to expansion
  'Crime Index:',
  'Expansion:',
  'Rent:',
  'Neighborhood:',
  'Metropolitan Statistical Area:',
  'Abstract:',
];

function AiAnalysis() {
  const {
    vestmap,
    setVestmap,
    modifyVestmap,
    analysisCompletionFlag,
    setAnalysisCompletionFlag,
    analysisLoader,
    setAnalysisLoader,
    analysisError,
    setAnalysisError,

    setFetchFromSidebar,
    fetchFromSidebar,
  } = useContext(VestmapContext);
  const [analysisText, setAnalysisText] = useState('');
  const [fetchFlag, setFetchFlag] = useState(false);
  const [textLoader, setTextLoader] = useState(false);

  const [outputs, setOutputs] = useState({
    abstract: '',
    income: '',
    demographics: '',
    hpi: '',
    neighborhood: '',
    schools: '',
    expansion: '',
    crime: '',
    rent: '',
    msa: '',
  });

  async function getVestmap(vestmapID) {
    try {
      const url = process.env.REACT_APP_NODE_URL + '/get-vestmap';
      const params = new URLSearchParams({
        id: vestmapID,
      });
      const res = await fetch(url + '?' + params);

      if (!res.ok) throw new Error('Failed to fetch vestmap');
      const data = await res.json();
      return data;
    } catch (error) {
      console.log('Error in fetching vestmap: ', error);
    }
  }

  async function fetchAiData() {
    try {
      setAnalysisText('');
      setTextLoader(true);
      setAnalysisLoader(true);
      setAnalysisError(false);

      console.log('Fetching AI data');
      const vestmapID = vestmap._id;
      const fetchedVestmap = await getVestmap(vestmapID);
      console.log({ fetchedVestmap });

      const vestmapString = JSON.stringify(fetchedVestmap);

      const url = new URL('https://api.dify.ai/v1/workflows/run');
      const payload = {
        inputs: {
          vestMapReport: JSON.stringify(fetchedVestmap),
        },
        response_mode: 'streaming',
        user: 'abc-123',
      };

      const stringPayload = JSON.stringify(payload);

      console.log('Payload: ', stringPayload);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer app-wZQR5PClfiEbWCJlJlERcq3i',
        },
        body: stringPayload,
      });

      const reader = response.body.getReader();
      let decoder = new TextDecoder();
      let result = '';
      let done = false;
      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        if (value) {
          result += decoder.decode(value, { stream: !done });
          const lines = result.split('\n');
          result = lines.pop();
          for (const line of lines) {
            if (line === '' || line === 'event: ping') {
              continue;
            }
            let newLine = line;
            if (line.startsWith('data: ')) {
              newLine = line.slice(6);
            }

            const event = JSON.parse(newLine);

            if (event.event === 'text_chunk') {
              setTextLoader(false);
              const newChunk = event.data.text;

              // TODO : Break entire thing into chunks here dynmically
              setAnalysisText(prev => prev + newChunk);
            }
            if (event.event === 'workflow_finished') {
              const obj = event.data.outputs;

              if (!obj) throw new Error('No data found in AI response');

              setOutputs({
                abstract: obj.abstractAnalysis,
                income: obj.incomeAnalysis,
                hpi: obj.hpiAnalysis,
                neighborhood: obj.transAnalysis,
                schools: obj.schoolAnalysis,
                expansion: obj.popAnalysis,
                crime: obj.crimeAnalysis,
              });
            }
          }

          if (done) {
            const event = JSON.parse(result);
            if (event.event === 'text') {
              setAnalysisText(prev => prev + event.data.text);
            }
          }
        }
      }
      setFetchFlag(true);

      console.log('AI data fetched successfully');
    } catch (error) {
      setAnalysisLoader(false);
      setAnalysisError(true);
      setTextLoader(false);
      console.log('Error in fetching AI data: ', error);
      console.error(error);
      alert(error);
    }
  }

  async function storeData() {
    try {
      console.log('Storing AI data');

      const url = process.env.REACT_APP_NODE_URL + '/ai-analysis/store-ai-data';
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          vestmapID: vestmap._id,
          aiAnalysis: outputs,
        }),
      });

      console.log(res.status);
      if (!res.ok) throw new Error("Couldn't store AI data");

      const updatedVestmap = { ...vestmap, ai_analysis: outputs };
      setVestmap(updatedVestmap);
      modifyVestmap(updatedVestmap);
      setAnalysisLoader(false);
      setAnalysisCompletionFlag(true);
      setFetchFlag(false);

      console.log('AI data stored successfully');
    } catch (error) {
      console.log('Error in storing AI data: ', error);
    }
  }

  useEffect(() => {
    console.log('Vestmap: ', vestmap);

    if (vestmap?.ai_analysis) {
      setAnalysisText(vestmap.ai_analysis.abstract);
      console.log(parseMarkdownString(vestmap.ai_analysis.abstract));
      return;
    }

    fetchAiData();
  }, []);

  useEffect(() => {
    if (fetchFlag) {
      storeData();
    }
  }, [fetchFlag]);

  useEffect(() => {
    if (fetchFromSidebar) {
      setAnalysisText('');
      fetchAiData();
      setFetchFromSidebar(false);
    }
  }, [fetchFromSidebar]);

  function parseMarkdownString(markdownString) {
    const cleanedString = markdownString.trim();

    const parts = cleanedString.split(/\n{2,}/);
    console.log(parts);

    if (parts.length < 2) {
      throw new Error('The input string does not have the expected format.');
    }

    const heading = parts[0].trim();
    let paragraph = parts[1] ? parts[1].trim() : '';

    paragraph = paragraph.replace(/^\*|\*$/g, '');

    return { heading, paragraph };
  }

  function removeColon(str) {
    if (str) {
      if (str.slice(-1) === ':') {
        return str.slice(0, -1);
      }
      return str;
    }
  }

  return (
    <>
      {textLoader === true ? (
        <Box
          style={{
            minHeight: '18rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingTop: '0rem',
          }}
        >
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
        </Box>
      ) : (
        <Box
          style={{
            minHeight: '18rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingTop: '0rem',
          }}
        >
          {vestmap?.ai_analysis?.abstract ? (
            <>
              <SectionHeading>Abstract</SectionHeading>

              <Markdown
                options={{
                  overrides: {
                    strong: {
                      component: ({ children }) => {
                        if (children[0] === 'Abstract') {
                          return <SectionHeading>{children}</SectionHeading>;
                        }
                        return <strong>{children}</strong>;
                      },
                    },
                    p: {
                      component: ({ children }) => {
                        if (children[0] === 'Abstract') {
                          return null;
                        }
                        return (
                          <SectionAnalysis abstract={true}>
                            {children}
                          </SectionAnalysis>
                        );
                      },
                    },

                    em: {
                      component: 'span',
                    },
                    li: {
                      component: ({ children }) => {
                        return (
                          <li>
                            <SectionAnalysis abstract={true}>
                              {children}
                            </SectionAnalysis>
                          </li>
                        );
                      },
                    },
                    br: {
                      component: () => {
                        return null;
                      },
                    },
                  },
                  forceBlock: true,
                }}
              >
                {parseMarkdownString(vestmap.ai_analysis.abstract).paragraph}
              </Markdown>

              <Markdown
                options={{
                  overrides: {
                    strong: {
                      component: ({ children }) => {
                        function stripColon(str) {
                          if (str.slice(-1) === ':') {
                            return str.slice(0, -1);
                          }
                          return str;
                        }

                        if (
                          stripColon(children[0]) === 'Income' ||
                          stripColon(children[0]) === 'Demographics' ||
                          stripColon(children[0]) === 'House Price Index' ||
                          stripColon(children[0]) ===
                            'Transitional Neighborhood Index (TNI)' ||
                          stripColon(children[0]) === 'Schools' ||
                          stripColon(children[0]) === 'Population Growth' ||
                          stripColon(children[0]) === 'Crime Index:' ||
                          stripColon(children[0]) === 'Expansion' ||
                          stripColon(children[0]) === 'Rent' ||
                          stripColon(children[0]) === 'Neighborhood' ||
                          stripColon(children[0]) ===
                            'Metropolitan Statistical Area:' ||
                          stripColon(children[0]) === 'Crime Index'
                        ) {
                          return <SectionHeading>{children}</SectionHeading>;
                        }
                        return <strong>{children}</strong>;
                      },
                    },
                    p: {
                      component: ({ children }) => {
                        return <SectionAnalysis>{children}</SectionAnalysis>;
                      },
                    },

                    em: {
                      component: 'span',
                    },
                    li: {
                      component: ({ children }) => {
                        return (
                          <li>
                            <SectionAnalysis>{children}</SectionAnalysis>
                          </li>
                        );
                      },
                    },
                    br: {
                      component: () => {
                        return null;
                      },
                    },
                  },
                  forceBlock: true,
                }}
              >
                {
                  // Add more fields here in the future
                  vestmap.ai_analysis?.income +
                    '\n\n\n\n' +
                    vestmap.ai_analysis?.hpi +
                    '\n\n\n\n' +
                    vestmap.ai_analysis?.neighborhood +
                    '\n\n\n\n' +
                    vestmap.ai_analysis?.schools +
                    '\n\n\n\n' +
                    vestmap.ai_analysis?.expansion +
                    '\n\n\n\n' +
                    vestmap.ai_analysis?.crime
                }
              </Markdown>
            </>
          ) : (
            <>
              <Markdown
                options={{
                  overrides: {
                    strong: {
                      component: ({ children }) => {
                        if (titles.includes(children[0])) {
                          return <SectionHeading>{children}</SectionHeading>;
                        }
                        return <strong>{children}</strong>;
                      },
                    },
                    p: {
                      component: ({ children }) => {
                        if (children[0] === 'Abstract') {
                          return null;
                        }
                        return <SectionAnalysis>{children}</SectionAnalysis>;
                      },
                    },

                    em: {
                      component: 'span',
                    },
                    li: {
                      component: ({ children }) => {
                        return (
                          <li>
                            <SectionAnalysis>{children}</SectionAnalysis>
                          </li>
                        );
                      },
                    },
                    br: {
                      component: () => {
                        return null;
                      },
                    },
                  },
                  forceBlock: true,
                }}
              >
                {analysisText}
              </Markdown>
            </>
          )}
        </Box>
      )}
    </>
  );
}

function SectionHeading({ children }) {
  return (
    <Typography
      variant='h5'
      style={{
        fontSize: '16px',
        fontWeight: 900,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#4FA490',
      }}
    >
      {children}
    </Typography>
  );
}

function SectionAnalysis({ children, abstract }) {
  return (
    <Typography
      variant='body1'
      style={{
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '20px',
        textAlign: 'left',
        marginTop: '1rem',
        fontStyle: abstract ? 'italic' : 'normal',
      }}
    >
      {children}
    </Typography>
  );
}

export default AiAnalysis;
