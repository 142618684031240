import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { loadModules } from 'esri-loader';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';

import { ChevronRight } from 'lucide-react';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const AnimatedIconButton = styled(IconButton)(({ theme, loading }) => ({
  animation: loading ? `${rotate} 1s linear infinite` : 'none',
}));

function MapEditor({ returnToHome }) {
  const [maps, setMaps] = useState([]);
  const [editingMap, setEditingMap] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [token, setToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchMaps();
    getArcgisToken();
  }, []);

  const fetchMaps = async () => {
    try {
      const url = process.env.REACT_APP_NODE_URL + '/map/templates';
      const response = await fetch(url);
      const data = await response.json();
      if (data.status && Array.isArray(data.maps)) {
        setMaps(data.maps);
      }
    } catch (error) {
      console.error('Error fetching maps:', error);
    }
  };

  const handleEdit = map => {
    setEditingMap({ ...map });
    setIsEditing(true);
    setOpen(true);
  };

  const handleAdd = () => {
    setEditingMap({
      title: '',
      description: '',
      key: '',
      webmapID: '',
    });
    setIsEditing(false);
    setOpen(true);
  };

  const handleSave = async () => {
    if (editingMap) {
      setIsSaving(true);
      try {
        const url = isEditing
          ? `${process.env.REACT_APP_NODE_URL}/map/update`
          : `${process.env.REACT_APP_NODE_URL}/map/addMap`;
        const method = isEditing ? 'PUT' : 'POST';

        console.log({ editingMap });

        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ map: editingMap }),
        });

        const data = await response.json();

        if (data.status) {
          console.log({ newMap: data?.map });
          if (isEditing) {
            const updatedMaps = maps.map(map =>
              map._id === editingMap._id ? data.map : map,
            );
            setMaps(updatedMaps);
          } else {
            setMaps([...maps, data.map]);
          }
          setOpen(false);
        } else {
          console.error('Failed to save map:', data.message);
        }
      } catch (error) {
        console.error('Error saving map:', error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  async function getArcgisToken() {
    if (token) {
      return;
    }

    console.log('Token not found. Generating new...');

    try {
      const url = process.env.REACT_APP_NODE_URL + '/admin/token';
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Response error');

      const data = await response.json();
      if (data?.token) {
        setToken(data.token.access_token);
        return;
      }

      throw new Error('Token not found');
    } catch (error) {
      console.error('Error getting token:', error);
    }
  }

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange = e => {
    if (editingMap) {
      setEditingMap({
        ...editingMap,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleDelete = async map => {
    if (window.confirm('Are you sure you want to delete this map?')) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_URL}/map/delete/${map._id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        const data = await response.json();

        if (data.status) {
          const updatedMaps = maps.filter(m => m._id !== map._id);
          setMaps(updatedMaps);
        } else {
          console.error('Failed to delete map:', data.message);
        }
      } catch (error) {
        console.error('Error deleting map:', error);
      }
    }
  };

  const handleSync = async () => {
    if (editingMap?.webmapID) {
      setLoading(true);
      try {
        await loadModules([
          'esri/Map',
          'esri/views/MapView',
          'esri/layers/FeatureLayer',
          'esri/widgets/Legend',
          'esri/config',
          'esri/smartMapping/statistics/classBreaks',
          'esri/WebMap',
          'esri/geometry/Extent',
          'esri/portal/PortalItem',
        ]).then(
          ([
            Map,
            MapView,
            FeatureLayer,
            Legend,
            esriConfig,
            classBreaks,
            WebMap,
            Extent,
            PortalItem,
          ]) => {
            if (!token) {
              console.error('Token not found');
              getArcgisToken();
              return;
            }

            esriConfig.apiKey = token;

            let mapItem = new PortalItem({
              id: editingMap.webmapID,
            });

            mapItem.load().then(function () {
              console.log({ title: mapItem.title, tags: mapItem.tags });
              editingMap.title = mapItem.title;
              editingMap.tags = mapItem.tags;

              setEditingMap({ ...editingMap });
            });
          },
        );
      } catch (error) {
        console.error('Error fetching map info:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        padding: '2rem',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          gutterBottom
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem',
            }}
          >
            <Typography
              sx={{
                color: '#39585c',
                fontSize: '1.5rem',
                fontWeight: '700',
                cursor: 'pointer',
              }}
              onClick={returnToHome}
            >
              Admin Panel
            </Typography>

            <ChevronRight
              style={{
                color: '#39585c',
                height: '1.5rem',
                width: '1.5rem',
              }}
            />

            <Typography
              sx={{
                color: '#39585c',
                fontSize: '1.5rem',
                fontWeight: '700',
                display: 'flex',
                justifyContent: 'space-between',
                textDecoration: 'underline',
              }}
            >
              Map Editor
            </Typography>
          </Box>
          <Button
            variant='contained'
            sx={{
              backgroundColor: '#39585c',
              color: '#fff',
              marginLeft: '1rem',
              '&:hover': {
                backgroundColor: '#39585c',
                color: '#fff',
              },
            }}
            onClick={handleAdd}
          >
            Add Map
          </Button>
        </Box>
        <Grid
          container
          sx={{
            gap: '1rem',
          }}
        >
          {maps.map(map => (
            <MapBox
              key={map._id}
              sectionKey={map.key}
              mapImage={map.imageURL}
              sectionTitle={map.title}
              mapDescription={map.description}
              editMap={() => handleEdit(map)}
              deleteMap={() => handleDelete(map)}
            />
          ))}
        </Grid>
      </Box>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{isEditing ? 'Edit Map' : 'Add Map'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            name='title'
            label='Title'
            type='text'
            fullWidth
            variant='outlined'
            value={editingMap?.title || ''}
            onChange={handleChange}
          />
          <TextField
            margin='dense'
            name='description'
            label='Description'
            type='text'
            fullWidth
            variant='outlined'
            multiline
            rows={4}
            value={editingMap?.description || ''}
            onChange={handleChange}
          />
          <TextField
            margin='dense'
            name='key'
            label='Key'
            type='text'
            fullWidth
            variant='outlined'
            value={editingMap?.key || ''}
            onChange={handleChange}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              margin='dense'
              name='webmapID'
              label='WebMap ID'
              type='text'
              fullWidth
              variant='outlined'
              value={editingMap?.webmapID || ''}
              onChange={handleChange}
            />
            <AnimatedIconButton
              onClick={handleSync}
              color='primary'
              loading={loading ? 1 : 0}
            >
              <SyncIcon />
            </AnimatedIconButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color='primary'
            variant='contained'
            disabled={isSaving}
            startIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function MapBox({
  sectionKey,
  mapImage,
  sectionTitle,
  mapDescription,
  editMap,
  deleteMap,
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteMap();
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Box
      key={sectionKey}
      sx={{
        alignSelf: 'center',
        '@media (max-width: 600px)': {
          width: '100%',
        },
        '@media only screen and (min-width: 600px) and (max-width: 1023px)': {
          width: '49%',
        },
        '@media only screen and (min-width: 1024px)': {
          width: '32%',
        },
        borderRadius: '1.21144rem',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginY: '1rem',
        boxShadow: '5px 20px 30px 0px rgba(229, 229, 229,0.7)',
      }}
    >
      {/* Image */}
      <Box
        sx={{
          width: '100%',
          height: '150px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {mapImage ? (
          <img
            src={mapImage || ''}
            alt=''
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
              transform: 'scale(1.8)',
            }}
          />
        ) : (
          <div
            style={{
              width: '100%',
              height: '200px', // You can adjust the height as needed
              backgroundColor: 'gray',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '16px',
            }}
          >
            No Map Available
          </div>
        )}
      </Box>

      {/* Title and Description */}
      <Box
        sx={{
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        {/* Title */}
        <Typography
          variant='h6'
          component='h3'
          textAlign={'center'}
          sx={{
            fontFamily: '"Lato", sans-serif',
            fontSize: '1.25rem',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '140%',
            textAlign: 'left',
            color: '#323142',
          }}
        >
          {sectionTitle}
        </Typography>

        {/* Description */}
        <Typography
          sx={{
            fontFamily: 'Lato',
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '20px',
            textAlign: 'left',
          }}
        >
          {mapDescription}
        </Typography>

        {/* Button */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '1rem',
          }}
        >
          <Button
            variant='contained'
            sx={{
              backgroundColor: '#39585c',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#39585c',
                color: '#fff',
              },
            }}
            onClick={editMap}
          >
            Edit Map
          </Button>

          <Button
            variant='contained'
            sx={{
              backgroundColor: '#880808',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#880808',
                color: '#fff',
              },
            }}
            onClick={handleDelete}
            disabled={isDeleting}
            startIcon={isDeleting ? <CircularProgress size={20} /> : null}
          >
            Delete Map
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default MapEditor;
