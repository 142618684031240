import { useEffect, useRef } from 'react';
import { loadModules } from 'esri-loader';

// export default function MapTags() {
//   const mapRef = useRef(null);

//   async function fetchMaps() {
//     try {
//       const response = await fetch('http://localhost:3005/map/templates');

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const maps = await response.json();
//       return maps.maps;
//     } catch (error) {
//       console.error(
//         'There has been a problem with your fetch operation:',
//         error,
//       );
//     }
//   }

//   // const updateMapTags = async (mapID, tags) => {
//   //   try {
//   //     const response = await fetch('http://localhost:3005/map/add-tags', {
//   //       method: 'PUT',
//   //       headers: {
//   //         'Content-Type': 'application/json',
//   //       },
//   //       body: JSON.stringify({ mapID, tags }),
//   //     });

//   //     if (response.ok) {
//   //       const data = await response.json();
//   //       console.log('Tags updated successfully:', data);
//   //     } else {
//   //       const errorData = await response.json();
//   //       console.error('Failed to update tags:', errorData);
//   //     }
//   //   } catch (error) {
//   //     console.error('Error updating tags:', error);
//   //   }
//   // };

//   async function fetchLayerUrlsAndTitles(webmapID, token) {
//     try {
//       const layerFetchingUrl = `https://www.arcgis.com/sharing/rest/content/items/${webmapID}/data`;
//       const layerFetchingParams = new URLSearchParams({ f: 'pjson', token });
//       const response = await fetch(
//         `${layerFetchingUrl}?${layerFetchingParams.toString()}`,
//         {
//           method: 'GET',
//           headers: { 'Content-Type': 'application/json' },
//         },
//       );

//       if (!response.ok) {
//         throw new Error("Couldn't fetch the layers");
//       }

//       const data = await response.json();
//       if (!data.operationalLayers) {
//         throw new Error('Operational layers not found in the webmap');
//       }

//       const layersInfo = [];
//       data.operationalLayers.forEach(operationalLayer => {
//         if (operationalLayer?.url) {
//           const baseLayerInfo = {
//             url: operationalLayer.url,
//             title: operationalLayer.title || 'No title available',
//           };

//           layersInfo.push(baseLayerInfo);

//           if (operationalLayer.visibleLayers) {
//             const finalPart =
//               operationalLayer.url.split('/')[
//                 operationalLayer.url.split('/').length - 1
//               ];
//             if (!+finalPart) {
//               operationalLayer.visibleLayers.forEach(layer => {
//                 const visibleLayerInfo = {
//                   url: `${operationalLayer.url}/${layer}`,
//                   title: operationalLayer.title || `Layer ${layer}`,
//                 };
//                 layersInfo.push(visibleLayerInfo);
//               });
//             }
//           }
//         }

//         if (operationalLayer?.layers) {
//           operationalLayer.layers.forEach(layer => {
//             if (layer?.url) {
//               const layerInfo = {
//                 url: layer.url,
//                 title: layer.title || 'No title available',
//               };
//               layersInfo.push(layerInfo);
//             }
//           });
//         }
//       });

//       return layersInfo;
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   async function fetchFields(layerUrls, token) {
//     const fieldPromises = layerUrls.map(async url => {
//       try {
//         const response = await fetch(`${url}?f=pjson&token=${token}`);
//         if (!response.ok) {
//           throw new Error(`Failed to fetch data from ${url}`);
//         }
//         const data = await response.json();
//         return {
//           name: data.name || '',
//           fields: data.fields || [],
//           serviceURL: url,
//         };
//       } catch (error) {
//         console.error(`Error fetching fields from ${url}:`, error);
//         return {
//           name: '',
//           fields: [],
//           serviceURL: null,
//         };
//       }
//     });

//     const fieldsResponse = await Promise.all(fieldPromises);

//     fieldsResponse.forEach(fieldObject => {
//       const filteredFields = fieldObject.fields.filter(
//         field => !field.alias.includes('2028'),
//       );
//       fieldObject.fields = filteredFields;
//     });

//     const uniqueFields = [];
//     const fieldNames = new Set();

//     fieldsResponse.forEach(fieldObj => {
//       fieldObj.fields.forEach(field => {
//         if (!fieldNames.has(field.name)) {
//           fieldNames.add(field.name);
//           uniqueFields.push({
//             group: fieldObj.name,
//             title: field.alias,
//             status: false,
//             type: field.type,
//             prefix: null,
//             suffix: null,
//             outfields: [field.name],
//             serviceURL: fieldObj.serviceURL,
//           });
//         }
//       });
//     });

//     return uniqueFields;
//   }

//   async function addBlocksToMap(mapID, blocks) {
//     try {
//       const response = await fetch('http://localhost:3005/map/add-blocks', {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ id: mapID, blocks }),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Blocks added successfully:', data);
//       } else {
//         const errorData = await response.json();
//         console.error('Failed to add blocks:', errorData);
//       }
//     } catch (error) {
//       console.error('Error adding blocks:', error);
//     }
//   }

//   async function getBlocks(maps) {
//     try {
//       const token =
//         '3NKHt6i2urmWtqOuugvr9QpDXY_PM9yMlJqg28A0fJxKVPCf7w2dtLcXs7wxHlGPYErJjDyqbbQuOo64B8V9NCmkDeL4e0csKV76BqM0e_GNo1IPCX-YKVrzBxX4EFEy';

//       const mapPromises = maps.map(async map => {
//         console.log('Currently fetching blocks of ', map.title);
//         const layerUrlsAndTitles = await fetchLayerUrlsAndTitles(
//           map.webmapID,
//           token,
//         );

//         if (!layerUrlsAndTitles) {
//           console.log(
//             'Found no operational layers for this map. Skipping...',
//             map.title,
//           );

//           await addBlocksToMap(map._id, []);

//           return;
//         }
//         const layerUrls = layerUrlsAndTitles.map(item => item.url);
//         const newBlocks = await fetchFields(layerUrls, token);
//         if (newBlocks.length === 0) {
//           console.log('The map has no fields. ', map.title);
//         }

//         await addBlocksToMap(map._id, newBlocks);

//         console.log({ newBlocks });
//       });

//       await Promise.all(mapPromises);

//       console.log('[Blocks fetched]');
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   // for updating map tags of all maps
//   // useEffect(() => {
//   //   let view;
//   //   loadModules([
//   //     'esri/Map',
//   //     'esri/views/MapView',
//   //     'esri/layers/FeatureLayer',
//   //     'esri/widgets/Legend',
//   //     'esri/config',
//   //     'esri/smartMapping/statistics/classBreaks',
//   //     'esri/WebMap',
//   //     'esri/geometry/Extent',
//   //     'esri/portal/PortalItem',
//   //   ]).then(
//   //     ([
//   //       Map,
//   //       MapView,
//   //       FeatureLayer,
//   //       Legend,
//   //       esriConfig,
//   //       classBreaks,
//   //       WebMap,
//   //       Extent,
//   //       PortalItem,
//   //     ]) => {
//   //       esriConfig.apiKey =
//   //         '3NKHt6i2urmWtqOuugvr9X_DX-IgjeQvkUJswjmVsarcpcZVCRXMJL0KXB6JwdTM08thc537e4FWEgKr2tYTL83YIKgTH6K5W55AC5MpYxYNzwCxSuX9uAik2cNfylxz'; // expires after 2 hours

//   //       fetchMaps().then(maps => {
//   //         maps.forEach(map => {
//   //           if (!map.webmapID) return;
//   //           let mapItem = new PortalItem({
//   //             id: map.webmapID,
//   //           });

//   //           mapItem.load().then(function () {
//   //             console.log({ title: mapItem.title, tags: mapItem.tags });
//   //             const tags = mapItem.tags;
//   //             updateMapTags(map._id, tags);
//   //           });
//   //         });
//   //       });
//   //     },
//   //   );

//   //   return () => {
//   //     if (view) {
//   //       view.destroy();
//   //     }
//   //   };
//   // }, []);

//   // for getting the blocks of all maps
//   // useEffect(() => {
//   //   fetchMaps().then(maps => {
//   //     getBlocks(maps);
//   //   });
//   // }, []);

//   return (
//     <div ref={mapRef} style={{ height: '30rem', width: '100%' }}>
//       MapTag Page (Delete Afer Use)
//     </div>
//   );
// }

export default function MapTag() {
  async function fetchData(url, options) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error(
        'There has been a problem with your fetch operation:',
        error,
      );
      throw error;
    }
  }

  useEffect(() => {
    const fetchPlants = async () => {
      const url = `https://plantmanager-bff-22066.nodechef.com/api/allPlants`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-api-key':
            '931c09ef4bfd53bdba8e73a542cf06554877269afc81d0b7b6c660ff64bb8231',
        },
      };

      const urlParams = new URLSearchParams({
        limit: 20,
        skip: 0,
      });

      try {
        const data = await fetchData(`${url}?${urlParams}`, options);
        console.log('Plants fetched:', data);
      } catch (error) {
        console.error('Error fetching plants:', error);
      }
    };

    fetchPlants();
  }, []);

  return <div>MapTag Page (Delete Afer Use)</div>;
}
