import { ChevronLeft, ChevronRight } from 'lucide-react';

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
      }}
      aria-label='Pagination'
    >
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
          borderTopLeftRadius: '0.375rem',
          borderBottomLeftRadius: '0.375rem',
          padding: '0.5rem',
          color:
            currentPage === 1
              ? 'rgba(107, 114, 128, 0.5)'
              : 'rgba(107, 114, 128, 1)',
          border: '1px solid rgba(209, 213, 219, 1)',
          backgroundColor:
            currentPage === 1 ? 'rgba(243, 244, 246, 1)' : 'transparent',
          cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
        }}
      >
        <span
          style={{
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0,0,0,0)',
            border: '0',
          }}
        >
          Previous
        </span>
        <ChevronLeft
          style={{ height: '1.25rem', width: '1.25rem' }}
          aria-hidden='true'
        />
      </button>
      <div style={{ display: 'flex' }}>
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => onPageChange(number)}
            style={{
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              padding: '0.5rem 1rem',
              fontSize: '0.875rem',
              fontWeight: '600',
              color: number === currentPage ? 'white' : 'rgba(17, 24, 39, 1)',
              backgroundColor:
                number === currentPage ? '#39585c' : 'transparent',
              border: '1px solid rgba(209, 213, 219, 1)',
              cursor: 'pointer',
              zIndex: number === currentPage ? '10' : '1',
            }}
          >
            {number}
          </button>
        ))}
      </div>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
          padding: '0.5rem',
          color:
            currentPage === totalPages
              ? 'rgba(107, 114, 128, 0.5)'
              : 'rgba(107, 114, 128, 1)',
          border: '1px solid rgba(209, 213, 219, 1)',
          backgroundColor:
            currentPage === totalPages
              ? 'rgba(243, 244, 246, 1)'
              : 'transparent',
          cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
        }}
      >
        <span
          style={{
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0,0,0,0)',
            border: '0',
          }}
        >
          Next
        </span>
        <ChevronRight
          style={{ height: '1.25rem', width: '1.25rem' }}
          aria-hidden='true'
        />
      </button>
    </nav>
  );
}
