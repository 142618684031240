import { useState, useEffect } from 'react';
import { X } from 'lucide-react';

export default function Alert({
  title = 'Alert',
  message = 'This is an alert message.',
  onClose,
}) {
  const [isVisible, setIsVisible] = useState(true);

  console.log('I am called');
  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();
    }
  };

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 50,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '0.5rem',
          boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
          maxWidth: '28rem',
          width: '100%',
          margin: '1rem',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem',
            backgroundColor: '#39585c',
          }}
        >
          <h3
            style={{ fontSize: '1.125rem', fontWeight: '600', color: 'white' }}
          >
            {title}
          </h3>
          <button
            onClick={handleClose}
            style={{
              color: 'white',
              cursor: 'pointer',
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label='Close alert'
          >
            <X style={{ height: '1.25rem', width: '1.25rem' }} />
          </button>
        </div>
        <div style={{ padding: '1rem' }}>
          <p style={{ color: '#4a5568' }}>{message}</p>
        </div>
      </div>
    </div>
  );
}
