import { useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { CircularProgress, Box } from '@mui/material';
import CustomVestmapViewer from '../../CustomVestmapViewer';

export default function PublicReport() {
  const { reportID } = useParams();
  const [searchParams] = useSearchParams();

  const [vestmap, setVestmap] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [accessToken, setAccessToken] = useState(null);

  console.log({ reportID, accessToken });

  async function getReport() {
    try {
      const url = process.env.REACT_APP_NODE_URL + '/record/' + reportID;
      const response = await fetch(url);
      console.log({ response });
      const data = await response.json();
      console.log({ data });
      setVestmap(data);
    } catch (e) {
      console.error(e);
    }
  }

  async function startupRoutine() {
    const token = searchParams.get('access_token');
    if (token) {
      setAccessToken(token);
    }
    await getReport();
    setLoading(false);
  }

  function closeViewer(state) {}

  useEffect(() => {
    startupRoutine();
  }, []);

  return (
    <div>
      {loading === true ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <CustomVestmapViewer
          open={openModal}
          setOpen={setOpenModal}
          viewerState={closeViewer}
          vestmapData={vestmap}
          arcgisToken={accessToken}
          publicView={true}
        />
      )}
    </div>
  );
}
