import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useAuth } from '../../AuthProvider';
import { useNavigate } from 'react-router-dom';

function PlaceHolderPage() {
  const { user } = useAuth();
  const navigate = useNavigate();

  async function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const pollUser = async outsettaID => {
    console.log({ outsettaID });
    let flag = false;
    while (!flag) {
      try {
        const url = new URL(
          process.env.REACT_APP_NODE_URL + '/user_data/poll/' + outsettaID,
        );
        const response = await fetch(url);

        if (response.status === 404) {
          console.log('User not found');
          await timeout(5000);
        } else if (response.status === 200) {
          console.log('User found');
          flag = true;
        }
      } catch (error) {
        console.log({ error });
      }
    }

    navigate('/run-reports');
  };

  useEffect(() => {
    console.log({ user });
    const outsettaUserID = user?.Uid;
    pollUser(outsettaUserID);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-bot'
        >
          <path d='M12 8V4H8' />
          <rect width='16' height='12' x='4' y='8' rx='2' />
          <path d='M2 14h2' />
          <path d='M20 14h2' />
          <path d='M15 13v2' />
          <path d='M9 13v2' />
        </svg>
      </Box>
      <Typography
        sx={{
          fontFamily: 'Lato',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: '#333',
          marginTop: '1rem',
        }}
      >
        We are setting up your account. Please hold tight...
      </Typography>
    </Box>
  );
}

export default PlaceHolderPage;
